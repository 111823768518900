
 <section class="investor-summary" *ngIf="data && layout">
  <ng-container *ngIf="!withholding">
    <div class="rows" *ngFor="let row of layout;let i=index" [ngClass]="{'header': row.isHeader}">
      <div [class]="'col-lg-2 col-md-3 col-sm-6 col-6 ' + (row.headerClass?row.headerClass: '')" [ngClass]="{'rowHeader': !row.isHeader}">
        <span>{{row.rowLabel}}</span>
        <div *ngIf="row.trackByProperty=='address' && enableAddressReview" class="AddressReviewText">{{addressReviewTxt}}</div>
      </div>
      
      <div [class]="'col-lg-2 col-md-3 col-sm-6 col-6 ' + (row.rowClass?row.rowClass: '')"
        [ngClass]="{'body-even': !row.isHeader && colIndex%2!==0, 'body-odd': !row.isHeader && colIndex%2===0}"
        *ngFor="let col of pagedData;let colIndex=index; trackBy:trackByValue.bind(this,i)">
        <ng-container [ngTemplateOutlet]="layout[i].rowControl"
          [ngTemplateOutletContext]="{ $implicit: col.value, columnIndex: colIndex }"></ng-container>
      </div>
    </div>
    
    
  </ng-container>

  <ng-container *ngIf="withholding">
    <div class="rows" *ngFor="let row of data; let rowIndex=index">
     
      <ng-container *ngIf="rowIndex===0">
        <div class="col-lg-2 col-md-3 col-sm-6 col-6 whHeader" *ngFor="let header of row.value; let h=index">
          <span>{{header.colLabel}}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="rowIndex > 0">
        <div class="col-lg-2 col-md-3 col-sm-6 col-6" *ngFor="let col of layout; let j=index" [ngClass]="{'body-even': !row.isHeader && j%2!==0, 'body-odd': !row.isHeader && j%2===0}">
          <ng-container [ngTemplateOutlet]="col.rowControl" [ngTemplateOutletContext]="{ $implicit: row.value, columnIndex: rowIndex }"></ng-container>
        </div>
      </ng-container>
    </div>
   
  </ng-container> 
</section>
<div class="row justify-content-end"> 
  <ul  class="pagination "> <li class="align-horizontal-right page-item page-link" [class.disabled]="activePage===1" (click)="scrollLeft(activePage - 1)"><div href="javascript:void(0);"> 
    < Previous</div></li></ul>
  <div class="text-overflow" #textoverflow>  
       <div > 
        <div aria-label="page navigation text-overflow"  >  
        <ul class="pagination " > 
          
          <li class="page-item"  #listitem *ngFor="let item of pages"   
            (click)="onClickPage(item)"><div class="page-link" [ngClass]="{'active': activePage === item}" href="javascript:void(0);">{{item}}</div></li>  
         
        </ul>  
      </div>  
     </div>  
      </div>  
      <ul  class="pagination ">
      <li class="align-horizontal-left page-item page-link" [class.disabled]="activePage===pageCount" (click)="scrollRight(activePage + 1)"><div href="javascript:void(0);">Next  ></div></li></ul>
</div>  
