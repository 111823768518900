import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SESSION_STORAGE } from '../configs/common-config';
import { LoginAuthService } from './login-auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IBrowserVersion, IBrowsers } from '../shared/models/iBrowserVersion';
import { AppService } from '../shared/services/app.service';
import { RaptorUiLoggerService } from 'raptor-appinsightlogger-ui';
import { ADL, LoggedUserNameKey, MultiLoginUrlSessionKey, SupportedBrowserList, enterOTPMsg, enterSSNMsg, enterZipMsg, enterpartnerCodeMsg, isMYEYLoginEnabled, isTINSecondFactorEnabled, isZipCodeSecondFactorEnabled, loggedUserEmailKey, otpAuth, otpEnabledProperty, otpGenerationDisabledMesg, otpInvalidEmailIdMsg, otpSecondAuthFactorEnabled, otpUnlockProcessError, partnerCodeOption, ssnOption, stateTokenSessionVarKey, userLockoutMessg, zipCodeOption, isPartnerCodeSecondFactorEnabled } from '../shared/constants/common.constants';
import { LoginService } from './login.service';
import { take } from 'rxjs/operators';
import { NotificationService, TextboxComponent } from 'eycomponentlibrary';
import { ThemeService } from '../themes/theme.service';
import { iOTPValidationReponse } from '../shared/models/iOTPValidationReponse';
import { iOTPGenerationReponse } from '../shared/models/iOTPGenerationReponse';
import { environment } from 'src/environments/environment';
import { NotificationMessage } from 'src/assets/constants/app-constants';
import { iSecondFactorValidationResponse } from '../shared/models/iSecondFactorValidationResponse';
import { Subscription, timer } from 'rxjs';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  showClearCookieModal: boolean;
  showBrowserSupportModal: boolean;
  browserData: IBrowserVersion;
  displayObject: { name: string, version: string }[];
  enableLogIn: boolean;
  userEmail: string = '';
  otpValidationView: boolean = false;
  secondFactorValidationView: boolean = false;
  isSSNopted: boolean = false;
  textForSSN: string = 'Enter the last 4 digits of Tax Identification Number';
  maxlengthSSNZip: string = '4';
  maxlengthOTP: string = '6';
  enableOTPFeature: boolean = true;
  isMYEYLoginEnabled: boolean = true;
  textBoxType: string = 'password';
  otpError: boolean = false;
  otpErrorMessage: string = 'The verfiication code could not be validated. Please try again.';
  isTINenabled: boolean = false;
  isZipCodeenabled: boolean = false;
  isSecondAuthBypassed: boolean = false;
  resendDisabled: boolean = false;
  countdown: number = 60;
  countdownSubscription: Subscription;
  isPartnerCodeEnabled: boolean = true;
  isZipCodeOpted: boolean = false;
  isPartnerCodeOpted: boolean = false;
  isZipCodeSecondFactorEnabled: boolean = true;
  isTINSecondFactorEnabled: boolean = true;
  isPartnreCodeSecondFactorEnabled: boolean = true;
  logo: string;
  title: string;
  isEYBranded: boolean = true;
  emailId: string = '';  
  verificationCode: string = '';
  mfaValue: string = '';  
  faCopyright = faCopyright;
  scpPrivacyNotice: string ='https://www.ey.com/en_us/legal-and-privacy/privacy-statement'
  scpTerms: string ='https://www.ey.com/en_us/legal-and-privacy' 

  constructor(
    private authService: LoginAuthService,
    private route: ActivatedRoute,
    private appSvc: AppService,
    private appInsight: RaptorUiLoggerService,
    private loginSvc: LoginService,
    private router: Router,
    private notifyService: NotificationService,
    private themeSvc: ThemeService
    ) {
    this.browserData = this.route.snapshot.data.browserData;
    this.checkBrowserSupport();
    this.otpError = false;
  }

  ngOnInit() {
    this.showClearCookieModal = false;
    environment.enableSingleTenant ? this.enableLogIn = true : this.enableLogIn = false;
    if (this.router.routerState.snapshot.url?.split('/')?.indexOf(btoa(otpAuth)) != -1) {
      this.enableOTPFeature = true;
      this.isMYEYLoginEnabled = false;
      sessionStorage.setItem(MultiLoginUrlSessionKey, otpAuth);
    } else if (this.router.routerState.snapshot.url?.split('/')?.indexOf(btoa(ADL)) != -1) {
      this.enableOTPFeature = false;
      this.isMYEYLoginEnabled = true;
      sessionStorage.setItem(MultiLoginUrlSessionKey, ADL);
    } else {
      this.enableOTPFeature = this.themeSvc.activeTheme?.properties[otpEnabledProperty];
      this.isMYEYLoginEnabled = this.themeSvc.activeTheme?.properties[isMYEYLoginEnabled];
    }
    this.isSecondAuthBypassed = this.themeSvc.activeTheme?.properties[otpSecondAuthFactorEnabled];
    this.isZipCodeSecondFactorEnabled = this.themeSvc.activeTheme?.properties[isZipCodeSecondFactorEnabled];
    this.isTINSecondFactorEnabled = this.themeSvc.activeTheme?.properties[isTINSecondFactorEnabled];
    this.isPartnreCodeSecondFactorEnabled = this.themeSvc.activeTheme?.properties[isPartnerCodeSecondFactorEnabled];
    this.isEYBranded = this.themeSvc.activeTheme.properties['--isEYBranding'];
    this.logo = this.themeSvc.activeTheme.properties['--headerLogov3'];
    this.title = this.themeSvc.activeTheme.title ?? 'Investor Portal'; 
  }


  async login_Click() {
    await this.login(false);
  }

  async login_internal_Click() {
    await this.login(true);
  }
  //-------------- OTP Login button functionality---------------------------

  sendOTP(resendEmail = '') {
    var encryptedClientId = sessionStorage.getItem('cid');
    var loginView = sessionStorage.getItem(MultiLoginUrlSessionKey);
    if (!resendEmail)
      sessionStorage.clear();
    sessionStorage.setItem('cid', encryptedClientId);
    sessionStorage.setItem(MultiLoginUrlSessionKey, loginView);
    this.appSvc.setAuthMode(true);
    var emailId = resendEmail ? resendEmail : this.emailId;
    sessionStorage.setItem(loggedUserEmailKey, emailId);
    try {
      if (emailId) {
        this.loginSvc.generateOTP(emailId, encryptedClientId).pipe(take(1)).subscribe((generateOtpResponse: iOTPGenerationReponse) => {
          if (generateOtpResponse?.userTokenResponse?.isOtpSent) {
            this.notifyService.success(generateOtpResponse?.userTokenResponse?.message);
            //storing in session storage
            sessionStorage.setItem(stateTokenSessionVarKey, generateOtpResponse?.userTokenResponse?.stateToken);
            this.appSvc.setOTPStateToken(generateOtpResponse?.userTokenResponse?.stateToken);
            sessionStorage.setItem(LoggedUserNameKey, generateOtpResponse?.loggedInUser);
            this.userEmail = emailId;
            this.otpValidationView = true;
            this.secondFactorValidationView = false;
          }
          else {
            this.notifyService.error(generateOtpResponse?.userTokenResponse?.message)
          }
        },
          (error) => this.notifyService.error(otpInvalidEmailIdMsg))
      }
      else {
        this.notifyService.error(otpInvalidEmailIdMsg);
      }
    }
    catch (error) {
      this.notifyService.error(otpInvalidEmailIdMsg);
    }
  }


  login_otp_Click() {
    this.sendOTP();
  }

  resendOTP() {
    this.appSvc.isOTPResendBtnDisabled().pipe(take(1)).subscribe(isOTPResendBtnDisabled => {
      if (isOTPResendBtnDisabled) {
        this.notifyService.error(otpGenerationDisabledMesg);
      }
      else {
        this.sendOTP(this.userEmail);
        this.resendDisabled = true;
        this.appSvc.setOTPResendBtnDisabled(true);
        this.startTimer();
      }
    })
  }

  startTimer() {
    this.countdown = 60; // count set to 30

    const source = timer(1000, 1000); // Emit a value every second

    this.countdownSubscription = source.subscribe(() => {
      if (this.countdown > 0) {
        this.countdown--; // Decrement the countdown
      } else {
        // Enable the button when the countdown reaches 0
        this.resendDisabled = false;
        this.appSvc.setOTPResendBtnDisabled(false);
        // Unsubscribe from the timer
        this.countdownSubscription.unsubscribe();
      }
    });
  }

  validate_otp_Click() {
    var emailId = this.userEmail;
    var passcode = this.verificationCode;
    if (!passcode) {
      this.notifyService.error(enterOTPMsg);
      return;
    }
    var stateToken = this.appSvc.getOTPStateToken();
    const encryptedClientId = sessionStorage.getItem('cid');
    this.loginSvc.validateOTP(emailId, passcode, stateToken, encryptedClientId).pipe(take(1)).subscribe((userTokenValidationResponse: iOTPValidationReponse) => {
      if (userTokenValidationResponse?.userTokenValidationResponse?.isTokenValid) {
        this.otpError = false;
        this.appSvc.setOTPToken(userTokenValidationResponse.userTokenValidationResponse.jwtToken);
        this.appSvc.setClient({ id: userTokenValidationResponse?.clientId, clientName: '' });// setting the clientId after validation for OTP login only
        sessionStorage.setItem('dcid', userTokenValidationResponse?.clientId);
        this.isTINenabled = this.isTINSecondFactorEnabled ? userTokenValidationResponse?.isOTPSecondAuthBypassRequired.tinEnabled : false;
        this.isZipCodeenabled = this.isZipCodeSecondFactorEnabled ? userTokenValidationResponse?.isOTPSecondAuthBypassRequired.zipEnabled : false;
        this.isPartnerCodeEnabled = this.isPartnreCodeSecondFactorEnabled ? userTokenValidationResponse?.isOTPSecondAuthBypassRequired.partnerCodeEnabled : false;
        if (!this.isSecondAuthBypassed) { //If Second factor turned on
          if (!this.isTINenabled && !this.isZipCodeenabled && !this.isPartnerCodeEnabled) {
            this.activateOTPLogin();
          }
          else {
            if (this.isTINenabled && this.isZipCodeenabled && this.isPartnerCodeEnabled) {
              this.partnerCodeOptionSelected();
            }
            else if (this.isTINenabled && !this.isZipCodeenabled && !this.isPartnerCodeEnabled) {
              this.ssnOptionSelected();
            }
            else if (this.isZipCodeenabled && !this.isTINenabled && !this.isPartnerCodeEnabled) {
              this.postalCodeOptionSelected();
            }
            else if (this.isPartnerCodeEnabled && !this.isTINenabled && !this.isZipCodeenabled) {
              this.partnerCodeOptionSelected();
            }
            else if (!this.isPartnerCodeEnabled && this.isTINenabled && this.isZipCodeenabled) {
              this.ssnOptionSelected();
            }
            else if (this.isPartnerCodeEnabled && this.isTINenabled && !this.isZipCodeenabled) {
              this.partnerCodeOptionSelected();
            }
            else if (this.isPartnerCodeEnabled && !this.isTINenabled && this.isZipCodeenabled) {
              this.partnerCodeOptionSelected();
            }
            this.secondFactorValidationView = this.isTINenabled || this.isZipCodeenabled || this.isPartnerCodeEnabled;
            this.otpValidationView = false;
          }
        }
        else {
          this.activateOTPLogin();
        }
      }
      else if (userTokenValidationResponse?.userTokenValidationResponse?.isUserLocked) {
        var lockedTimeleft = userTokenValidationResponse?.userTokenValidationResponse?.lockedSpanLeft;
        if (lockedTimeleft > 0) {
          this.otpErrorMessage = userLockoutMessg + userTokenValidationResponse?.userTokenValidationResponse?.lockedSpanLeft.toString() + ' minute(s).';
        }
        else {
          this.otpErrorMessage = otpUnlockProcessError;
        }
        this.otpError = true;
      }
      else {
        var attemptsRemainingWarning = ". You have " + userTokenValidationResponse?.userTokenValidationResponse?.attemptsRemaining
          + " attempts remaining.";
        this.otpErrorMessage = userTokenValidationResponse?.errorMessage + attemptsRemainingWarning; userTokenValidationResponse?.errorMessage + attemptsRemainingWarning;
        this.otpError = true;
      }
    },
      (error) => {
        console.error(error);
        this.notifyService.error(error.error && error.error.title ? error.error.title : NotificationMessage.genericError);
      });
  }

  validate2ndfactor_Click() {
    var emailId = this.userEmail;
    var stateToken = this.appSvc.getOTPStateToken();
    var MFAValue = (this.isSecondAuthBypassed || (!this.isSecondAuthBypassed && !this.isTINenabled && !this.isZipCodeenabled && !this.isPartnerCodeEnabled))
      ? '' : this.mfaValue;
    const encryptedClientId = sessionStorage.getItem('cid');
    var options = '';
    var ssn = '';
    var zipCode = '';
    var partnerCode = ''
    if (!this.isSecondAuthBypassed) {
      if (this.isSSNopted) {
        options = ssnOption;
        ssn = MFAValue;
      }
      else if (this.isZipCodeOpted) {
        options = zipCodeOption;
        zipCode = MFAValue;
      }
      else if (this.isPartnerCodeOpted) {
        options = partnerCodeOption;
        partnerCode = MFAValue;
      }
    }
    if (!this.isSecondAuthBypassed && this.isTINenabled && !MFAValue && this.isSSNopted && !this.isZipCodeOpted && !this.isPartnerCodeOpted) {
      this.notifyService.error(enterSSNMsg);
      return;
    }
    else if (!this.isSecondAuthBypassed && this.isZipCodeenabled && !MFAValue && this.isZipCodeOpted && !this.isSSNopted && !this.isPartnerCodeOpted) {
      this.notifyService.error(enterZipMsg);
      return;
    }
    else if (!this.isSecondAuthBypassed && this.isPartnerCodeEnabled && !MFAValue && !this.isSSNopted && !this.isZipCodeOpted && this.isPartnerCodeOpted) {
      this.notifyService.error(enterpartnerCodeMsg);
      return;
    }
    this.otpError = false;
    //using same request model & passcode is required field hence providing it as empty.
    this.loginSvc.validateSecondFactor(emailId, '', stateToken, encryptedClientId, options, ssn, zipCode, partnerCode).pipe(take(1))
      .subscribe((secondFactorValidationResponse: iSecondFactorValidationResponse) => {
        if (secondFactorValidationResponse?.isSFValid) {
          this.activateOTPLogin();
        }
        else {
          this.otpErrorMessage = secondFactorValidationResponse.scpMailbox ?
            secondFactorValidationResponse?.errorMessage + secondFactorValidationResponse.scpMailbox :
            secondFactorValidationResponse?.errorMessage;
          this.otpError = true;
        }
      },
        (error) => {
          console.error(error);
          this.notifyService.error(error.error && error.error.title ? error.error.title : NotificationMessage.genericError);
        });
  }

  private activateOTPLogin() {
    this.otpLogin();
    this.router.navigate(['app']);
  }

  ssnOptionSelected() {
    this.isSSNopted = true;
    this.isZipCodeOpted = false;
    this.isPartnerCodeOpted = false;
    this.textForSSN = 'Enter the last 4 digits of Tax Identification Number';
    this.maxlengthSSNZip = '4';
    if (document.getElementById("ssnzip") as HTMLInputElement)
      (document.getElementById("ssnzip") as HTMLInputElement).value = null;
    this.textBoxType = 'password';
  }

  postalCodeOptionSelected() {
    this.isZipCodeOpted = true;
    this.isPartnerCodeOpted = false;
    this.isSSNopted = false;
    this.textForSSN = 'Enter the Zip Code';
    this.maxlengthSSNZip = '20';
    if (document.getElementById("ssnzip") as HTMLInputElement)
      (document.getElementById("ssnzip") as HTMLInputElement).value = null;
    this.textBoxType = 'text';
  }

  partnerCodeOptionSelected() {
    this.isPartnerCodeOpted = true;
    this.isSSNopted = false;
    this.isZipCodeOpted = false;
    this.textForSSN = 'Enter the Partner Code';
    this.maxlengthSSNZip = '20';
    if (document.getElementById("ssnzip") as HTMLInputElement)
      (document.getElementById("ssnzip") as HTMLInputElement).value = null;
    this.textBoxType = 'text';
  }

  backBtnClicked() {
    if (this.otpValidationView) {
      this.otpValidationView = false;
    }
    else if (this.secondFactorValidationView) {
      this.secondFactorValidationView = false;
      this.otpValidationView = false;
      this.revokeJWTTokenonRefresh();
    }
    this.otpError = false;
  }

  //-------------- OTP Login button functionality---------------------------
  showCookiePopup_Click() {
    this.showClearCookieModal = true;
  }
  closePopup_Click() {
    this.showClearCookieModal = false;
  }

  private async login(IsInternal: boolean) {
    var encryptedCid = sessionStorage.getItem('cid');
    var loginView = sessionStorage.getItem(MultiLoginUrlSessionKey);
    sessionStorage.clear();
    sessionStorage.setItem('cid', encryptedCid);
    sessionStorage.setItem(MultiLoginUrlSessionKey, loginView);
    this.appSvc.setAuthMode(false);
    if (environment.enableSingleTenant) {
      this.setTenant('internal');
    }
    else {
      if (IsInternal) {
        this.setTenant('internal');
      } else {
        this.setTenant('external');
      }
    }
    await this.authService.setAuthConfig();
    this.authService.logIn();
  }

  private otpLogin() {
    var encryptedCid = sessionStorage.getItem('cid');
    sessionStorage.setItem('cid', encryptedCid);
    sessionStorage.setItem(SESSION_STORAGE.LOGIN_INITIATED, 'true');
  }

  private revokeJWTTokenonRefresh() {
    var encryptedCid = sessionStorage.getItem('cid');
    var isOTP = sessionStorage.getItem('authmode');
    if (isOTP && isOTP == otpAuth) {
      this.loginSvc.revokeTokenOnRefresh(encryptedCid, this.appSvc.getOTPStateToken()).subscribe(response => {
        if (response > 0) {
          this.appSvc.setOTPStateToken('');
          this.appSvc.setOTPAccessToken('');
          sessionStorage.removeItem('otpaccesstoken');
          sessionStorage.removeItem(stateTokenSessionVarKey);
        }
      });
    }
  }

  private setTenant(tenantType: 'internal' | 'external' | 'otp'): void {
    sessionStorage.setItem(SESSION_STORAGE.TENANT, tenantType);
  }

  private checkBrowserSupport() {
    let brwsrId = this.appSvc.browserId;
    let clientBrwsrAndVersion = this.appSvc.detectBrowserVersion();
    let clientBrwsrName = clientBrwsrAndVersion.split(' ')[0];
    let clientBrwsrVrsn = +clientBrwsrAndVersion.split(' ')[1];
    let clientOSName = this.appSvc.clientOS;
    if (clientOSName != false) {
      if (SupportedBrowserList.indexOf(clientBrwsrName) != -1) {
        let brwsrDetails: IBrowsers = this.browserData.browsers.filter(x => x.id == brwsrId.toLocaleLowerCase())[0];
        let stblBrwsrVrsn = Math.trunc(+Object.keys(brwsrDetails.versions).sort()[Object.keys(brwsrDetails.versions).length - 1]);
        this.showBrowserSupportModal = this.appSvc.isBrowserSupported(clientBrwsrVrsn, stblBrwsrVrsn, clientBrwsrName);
      } else {
        this.showBrowserSupportModal = true;
      }
    } else {
      this.showBrowserSupportModal = true;
    }
    if (this.showBrowserSupportModal) {
      clientOSName = 'Macintosh';
      let query = this.appSvc.getLatestVesionQuery(clientOSName);
      this.appSvc.getUpdatedBrowserInfromation(query).subscribe(brwsrRes => {
        this.displayObject = this.appSvc.getAllBrowserLatestandNameObject(brwsrRes);
      });
      this.appInsight.logEvent("Login from unsupported browser " + sessionStorage.getItem('cid') + " " + clientBrwsrName + " " + clientBrwsrVrsn);
    } else {
      sessionStorage.setItem("brwsrStatus", "valid");
    }
  }

  closeBrowserSupportModal() {
    this.showBrowserSupportModal = false;
  }

  openLink(url: string){
    window.open(url, "_blank");
  }
}
