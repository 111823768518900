import { Injectable, Injector } from '@angular/core';
import { SESSION_STORAGE } from '../configs/common-config';
import { MsalAuthService } from '../shared/services/msal-auth.service';

@Injectable({
    providedIn: 'root'
})
export class LoginAuthService {
    public isLogoutTriggered: any;

    constructor(
        private dpInjector: Injector
    ) {
        (<any>window).EY_INVP_logOutUser = this.logOut.bind(this);
      }

    public logIn() {
        const msalService = this.dpInjector.get<MsalAuthService>(MsalAuthService);
        if (msalService !== undefined) {
            msalService.loginRedirect();
            sessionStorage.setItem(SESSION_STORAGE.LOGIN_INITIATED, 'true');
        }
    }

    public logOut(sessionFlag: boolean =false,broadcastSession: boolean = false) {           
        if(sessionFlag) {
            this.loggedout(broadcastSession);
          }
          else{
            this.loggedout();
          }    
    }

    private loggedout(broadcastSession: boolean = false) {
        this.isLogoutTriggered = true;
        const msalService = this.dpInjector.get<MsalAuthService>(MsalAuthService);
        if (msalService !== undefined) {
            msalService.logout();
        }
        sessionStorage.removeItem(SESSION_STORAGE.LOGIN_INITIATED);
        sessionStorage.removeItem(SESSION_STORAGE.TENANT);
        sessionStorage.clear();

        let message = Object.assign({},
            {action:'sessionidleLogout'}
          );

          const broadcastChannel = new BroadcastChannel('logout_channel');
          broadcastSession? broadcastChannel.postMessage(message): broadcastChannel.postMessage({action:'logout'});
          broadcastChannel.close();
          // sessionStorage.setItem(SESSION_STORAGE.INTERACTION_REQUIRED,'true');
        
    }

    public async setAuthConfig() {
        const msalService = this.dpInjector.get<MsalAuthService>(MsalAuthService);
        if (msalService !== undefined) {
          await msalService.setAuthConfig();
        }
      }
}