import { Injectable } from '@angular/core';

import { IBrowserVersion } from '../../models/iBrowserVersion';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { SupportedBrowserList } from '../../constants/common.constants';


@Injectable({
  providedIn: 'root'
})
export class BrowserResolvereService  {

  constructor(private appService: AppService) { } 

   resolve(): Observable<IBrowserVersion> {
    let clientBrwsrAndVersion = this.appService.detectBrowserVersion();
    let clientBrwsrName = clientBrwsrAndVersion.split(' ')[0];
    let clientMachineOSNm = this.appService.getMachineOSName();
    this.appService.setClientOS(clientMachineOSNm);
    if(clientMachineOSNm != false){
      if(SupportedBrowserList.indexOf(clientBrwsrName) != -1) {
        let brwsrId = this.appService.getBrowserId(clientBrwsrName, clientMachineOSNm);
        this.appService.setBrowserId(brwsrId);
        let query = this.appService.getQuery(brwsrId);
        return this.appService.getUpdatedBrowserInfromation(query);
      }
    }
  }
}
