import { EYTheme } from 'src/app/themes/ey.theme';
import { OaktreeTheme } from 'src/app/themes/oaktree.theme';
import { LandmarkTheme } from 'src/app/themes/landmark.theme';
import { GSTheme } from 'src/app/themes/gs.theme';

const api = [
  'https://raptorapimd.ey.com/statecomposite/',
  'https://raptorapimd.ey.com/taxdocument/',
  'https://raptorapimd.ey.com/withholding/',
  'https://raptorapimd.ey.com/taxdocdropoff/',
  'https://raptorapimd.ey.com/passthroughentity/',
];

export const environment = {
  production: true,
  setIdleTime: 1720,
  setIdleTimeOut: 60,
  setKeepAlivePing: 10,
  apiEndpoint: api[0],
  documentApiEndpoint: api[1],
  withholdingApiEndpoint: api[2],
  documentdropoffApiEndpoint: api[3],
  pteApiEndPoint:api[4],
  protectedResourceUri: [
    api[0],
    api[1],
    api[2],
    api[3],
    api[4]
  ],
  configExternal: {
    authority: 'https://login.microsoftonline.com/99050bca-65fe-4f6d-8478-388cd894a57f',
    instance: 'https://login.microsoftonline.com/',
    postLogoutRedirectUrl: 'https://raptorscpd.ey.com/',
    tenant: '99050bca-65fe-4f6d-8478-388cd894a57f',
    clientId: 'bbdd2cc0-5ab2-4514-be86-d49cc0720cea',
    redirectUri: window.location.origin,
    scope: ['api://a1118b17-b538-4998-b83b-195b6cf4f904/user_impersonation'],
    cache: 'sessionStorage',
    extraQueryParameter: { domain_hint: 'eydmz.net' }
  },
  configInternal: {
    authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
    instance: 'https://login.microsoftonline.com/',
    postLogoutRedirectUrl: 'https://raptorscpd.ey.com/',
    tenant: '5b973f99-77df-4beb-b27d-aa0c70b8482c',
    clientId: '4c1fb6c3-9dec-4c51-95b4-c07dce0bf27b',
    redirectUri: window.location.origin,
    scope: ['api://4dd67b6a-98b8-4e62-a3d5-fa30d7cac242/user_impersonation'],
    cache: 'sessionStorage',
    extraQueryParameter: { domain_hint: 'ey.net' }
  },
  theme: {
    'Wb4CL60Q4jioWdJGWOgVrY-6l3hb6tvHN8sIAxTOLZWg1ls1vwk5lAE1TonK5KK3': OaktreeTheme,
    'gvkHLi741xGfYESFoxMDxfccgwbvm8kj9kbqKg32rMelodEVKa0DDvREmk5t4VIg': LandmarkTheme,
    'lcCiT9DqUte5XdCJ4BiPd7Ux2os65-lfNull9nbV0dvUoYrHcPxVZQgCjMiGCSOT': GSTheme,
    'default': EYTheme
  },
  cidGS:'lcCiT9DqUte5XdCJ4BiPd7Ux2os65-lfNull9nbV0dvUoYrHcPxVZQgCjMiGCSOT',
  appInsights: {   
    instrumentationKey: '1dfdbf0e-99b6-4037-953f-e924df464e1d',  
    roleName: 'RAPToR-INVP-UI',   
    appId : 'InvestorPortalUILogs', 
    logToConsole : true,
    logToAppInsights : true,
    clickAnalyticsEnabled : false
    },
    enableSingleTenant: true
};