import { Injectable } from '@angular/core';
import { HtmlEntities, HtmlEntityMapping } from '../constants/common.constants';
import { IServiceConfiguration } from '../models/iConfiguration';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor() { }

  public sortArray(array: any[], property: string, sortDirection: string) {
    if (sortDirection == 'desc') {
      array.sort((a, b) => a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0)
    }
    else {
      array.sort((a, b) => a[property] > b[property] ? 1 : a[property] < b[property] ? -1 : 0)
    }
  }

  /**Extract value of a configuration from a set of provided confiurations. */
  public extractFromConfiguration(configurations: IServiceConfiguration[], serviceName: string, configurationId: string){
    let serviceConfiguration = configurations.filter(x => x.serviceName === serviceName);
    return serviceConfiguration?.find(x => x.value.name === configurationId);
  }


  public encodePostRequest(postbody){
    let encodedBodyForRequest=encodeURIComponent(JSON.stringify(postbody).replace(HtmlEntities, m => HtmlEntityMapping[m]))
    return JSON.stringify(encodedBodyForRequest);
  }
}
