import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { DropdownModule, ModalModule,AccordionModule, TextboxModule } from 'eycomponentlibrary';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {  RaptorUiErrorHandler} from 'raptor-appinsightlogger-ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



@NgModule({
  declarations: [
    LoginComponent,
    LoginModalComponent],
  imports: [
    CommonModule,
    DropdownModule,
    ModalModule,
    AccordionModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TextboxModule,
    FormsModule
  ],
  providers:[
    { provide: ErrorHandler, useClass: RaptorUiErrorHandler }
  ],
  exports: [
    LoginComponent
  ]
})
export class LoginModule { }
