import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionIdleComponent } from './session-idle.component';
import { EY_LOGGER_CONFIG, RaptorUiErrorHandler} from 'raptor-appinsightlogger-ui';



@NgModule({
  declarations: [SessionIdleComponent],
  imports: [
    CommonModule
  ],
  providers:[
    { provide: ErrorHandler, useClass: RaptorUiErrorHandler }
  ],
  exports: [SessionIdleComponent]
})
export class SessionIdleModule { }
