export const GeneralErrorMessage: string = "An unexpected error occurred. Please try again.";

export const IsStateSourceIncomeEnabled: string="IsStateSourceIncomeEnabled";

export const SCPCompositeReportTextId= "SCWCompositeReportText";

export const SCPWHReportTextId= "WHConfirmationReport";
export const SCPCompositeAddressReview= "compositeAddressReviewEnabled";
export const SCPCompositeNAJurisdictions= "compositeNAJurisdictionEnabled";
export const CompositeEnablePartnerAddress="clientPartnerAddressEnabled";
export const BrowserSupportUrl = "https://browsersl.ist/api/browsers?q=";
export const SupportedBrowserList = ['Chrome', 'Firefox', 'Edge', 'Safari', 'CriOS', 'FxiOS', 'EdgiOS'];
export const LoggedUserNameKey = "loggedUserName";
export const otpAuth = 'otp';
export const ADL = 'adl';
export const msalAuth = 'msal';
export const ssnOption = 'SSN';
export const zipCodeOption = 'ZIPCODE';
export const partnerCodeOption = 'PARTNERCODE';
export const zip = 'Zip';
export const isMYEYLoginEnabled = '--isMYEYLoginEnabled';
export const otpEnabledProperty = '--isOTPFeatureEnabled';
export const otpSecondAuthFactorEnabled = '--isOTPSecondAuthFactorBypassed';
export const isZipCodeSecondFactorEnabled = '--isZipCodeSecondFactorEnabled';
export const isTINSecondFactorEnabled = '--isTINSecondFactorEnabled';
export const isPartnerCodeSecondFactorEnabled = '--isPartnerCodeSecondFactorEnabled';
export const otpInvalidEmailIdMsg="The verification code was not sent to the email address. Please try again with a valid email address";
export const enterOTPMsg="Please enter the verification code sent to your email address";
export const enterSSNMsg="Please enter the last 4 digit of your Tax Identification Number";
export const enterZipMsg="Please enter the Zip Code";
export const enterpartnerCodeMsg="Please enter the Partner Code";
export const enterSSNOrZipMsg="Please enter the Tax Identification Number or Zip Code";
export const stateTokenSessionVarKey="ST";
export const userLockoutMessg = "Your account is currently locked after too many failed attempts. Please try again after ";
export const otpGenerationDisabledMesg = "The verification code cannot be generated now. Please try again after sometime.";
export const otpUnlockProcessError = "Your account is currently in the process of being unlocked. Please try again.";
export const MultiLoginUrlSessionKey = "LoginView";
export const loggedUserEmailKey = "loginEmail";
export const HtmlEntityMapping = {
    '&nbsp;': ' ', '&amp;': '&', '&quot;': '"', '&apos;': "'",
    '&lt;': '<', '&gt;': '>', '&copy;': '©', '&reg;': '®',
    '&sect;': '§', '&middot;': '·', '<table': '<table',
    '\u003c': '<', '\u003e': '>', '\u0026': '&'
  };
  
  export const HtmlEntities = /&nbsp;|&amp;|&quot;|\\u003c|\\u003e|\\u0026|&apos;|&lt;|&gt;|&copy;|&reg;|&sect;|&middot;|<table/g;
  


export enum LoginType {
    b3Rw = 'otp',
    YWRs = 'adl'
}
