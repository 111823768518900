import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";
import { InteractionType, IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { envConfig, isIE, getTenantType, getProtectedResourceMap } from "./multitenant-config";
import { ADL, MultiLoginUrlSessionKey } from "../shared/constants/common.constants";

export function MSALConfigFactory(): IPublicClientApplication {
  const tenantType = getTenantType();
  return new PublicClientApplication({
    auth: {
      clientId: envConfig[tenantType]?.clientId,
      authority: envConfig[tenantType]?.authority,
      navigateToLoginRequestUrl: true,
      postLogoutRedirectUri: sessionStorage.getItem(MultiLoginUrlSessionKey) === ADL ? envConfig[tenantType]?.postLogoutRedirectUrl + sessionStorage.getItem('cid') + '/' + btoa(sessionStorage.getItem(MultiLoginUrlSessionKey)): envConfig[tenantType]?.postLogoutRedirectUrl + sessionStorage.getItem('cid'),
      redirectUri: envConfig[tenantType]?.redirectUri
      //validateAuthority: true
    },
    cache: {
      cacheLocation: envConfig[tenantType]?.cache,
      storeAuthStateInCookie: isIE
    }
  })
}

export function MSALAngularConfigFactory() {
  const tenantType = getTenantType();
  return {
    popUp: !isIE,
    consentScopes: [
      'user.read',
      'openid',
      'profile',
      ...envConfig[tenantType].scope
    ],
    protectedResourceMap: getProtectedResourceMap(),
    extraQueryParameters: envConfig.enableSingleTenant ? {} : envConfig[tenantType]?.extraQueryParameter
  };
}

export function GetScopes() {
  const tenantType = getTenantType();
  return envConfig[tenantType]?.scope;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: getProtectedResourceMap()
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const tenantType = getTenantType();

  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        'user.read',
        'openid',
        'profile',
        ...envConfig[tenantType].scope
      ],
      prompt: 'select_account'
    }
  };
}

export function ExtraQueryParameters(): any {
  const tenantType = getTenantType();
  return envConfig.enableSingleTenant ? {} : envConfig[tenantType].extraQueryParameter;
}
