export const COMPOSITE_CONFIGS = 'CompositeDashboard,ConfirmationReport,ClientSupportMailbox,Home,ClientPartnershipsEnabled,ClientPartnerAddressEnabled,IsStateSourceIncomeEnabled,SCWCompositeReportText,CompositeEntityTypesEnabled,CompositeTaxResidencyEnabled,CompositePartnerGroupEnabled,eyMailbox,CompositeAddressReviewEnabled,CompositeAddressReviewText,CompositeShowPartnerCode,CompositeNAJurisdictionEnabled,RowHeaderConfig,CompositeSelectionPageLanguage,CompositeIncomeOSPopupText,CompositeIncomeOSPopupEnabled,CompositeSurveyFlowOpted,CompositeV2SurveyStateSourceIncomeLanguage,CompositeV2ElectionStageHeaderLanguage,CompositeV2SelectElectionStageHeaderLanguage,CompositeV2SelectionLeftCheckBoxheaderText,CompositeV2SelectionRightCheckBoxheaderText,CompositeElectionWelcomeTitle,newUIEnabled';

export const placeholdersHomeScreen = [
    { id: '{@ClientName}', value: '' },
    { id: '{@PartnersGrid}', value: '' },
    { id: '{@PresentTaxYear}', value: '' },
    { id: '{@PreviousTaxYear}', value: '' },
    { id: '{@SurveyEndDate}', value: '' },
    { id: '{@ClientMailBox}', value: '' },
    { id: '{@FAQ}', value: 'FAQ' },
    { id: '{@EY Investor Portal Support Team}', value: 'EY Investor Portal Support Team' }
  ];

  export const CompositeSelectionPageLanguage=[{
    id: "CompositeSelectionPageLanguage",
    value:'Please select the jurisdictions you would like to be included in for the state tax composite returns from the below <b>Available Jurisdictions</b> list. For returning investors, your prior year elections have been added to the <b>Selected Jurisdictions</b> list below. For new investors, all other <b>Available Jurisdictions</b> are listed below, based on your investor entity type and state of residency provided.'
  }];

  export const CompositeSelectionPageLanguageFlag= "CompositeSelectionPageLanguage";

  export const SelectAllNoPY ="I choose to elect into all available composite filings for which I am eligible."
  export const SelectAllPY ="I choose to elect into all available composite filings for which I am eligible."
  //export const SelectAllNewWithSamePY =" I want to elect into all newly eligible state composite filings and keep prior year elections the same."
  export const SelectSpecificStates ="I choose to select specific jurisdiction(s) to participate in composite filings for which I am eligible."
  export const SelectOnlyMandatory ="I choose to <u>not</u> participate in any elective composite return filings understanding that I will be included in all mandatory composite filings."
  export const stateSourceIncomeDefaultrowheader ="States with Income from Other Activities*"

  export enum electionOptionValue {
    'selectAllNoPY'='op0',
    'selectAllPY'='op1',
    //'selectAllNewWithSamePY'='op2',
    'selectSpecificStates'='op2',
    'selectOnlyMandatory'='op3'
}

// export enum InfoBoxStatusColour {
//   Mandatory = '#168736',
//   Eligible = '#eb4f00',
//   PriorYearElectedIn= '#747480',
//   PriorYearElectedOut ='#155CB4'
// }

