import { Injectable } from '@angular/core';
import { ITheme } from './iTheme';
import { EYTheme } from './ey.theme';
import { OaktreeTheme } from './oaktree.theme';
import { environment } from 'src/environments/environment';
import { RaptorUiLoggerService } from 'raptor-appinsightlogger-ui';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _activeTheme: ITheme;
  constructor( 
    private appInsight: RaptorUiLoggerService
    ) { }

  get activeTheme() {
    return this._activeTheme;
  }

  setTheme(theme: ITheme) {
    this.appInsight.logEvent('setTheme started for active theme '+theme.name);
    if (theme && theme.properties) {
      for (const prop in theme.properties) {
        document.body.style.setProperty(prop, theme.properties[prop]);
      }
      if (this._activeTheme) {
        document.body.classList.remove(theme.name);
      }
      document.body.classList.add(theme.name);
      this._activeTheme = theme;
    }
  }
  setThemeByClient() {
    const client = this.getParameterByName('cid');
    this._activeTheme = environment.theme[client];
    if(!this._activeTheme){
      this._activeTheme = environment.theme['default'];
    }
    this.setTheme(this._activeTheme);
  }

  private getParameterByName(name) {
    return sessionStorage.getItem(name);
  }

  // setThemeByClient(){
  //   var client=this.getParameterByName("client");
  //   switch(client){
  //     case "ey":
  //     default:
  //       this._activeTheme=EYTheme;
  //       break;
  //   }
  //   this.setTheme(this._activeTheme);
  // }

  // private getParameterByName(name, url?) {
  //   if (!url) url = window.location.href;
  //   name = name.replace(/[\[\]]/g, '\\$&');
  //   let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
  //       results = regex.exec(url);
  //   if (!results) return null;
  //   if (!results[2]) return '';
  //   return decodeURIComponent(results[2].replace(/\+/g, ' '));
  // }
}
