import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { catchError, filter, take } from 'rxjs/operators';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { NavigationEnd, Router } from '@angular/router';
import { MsalAuthService } from '../../services/msal-auth.service';
import { Subscription } from 'rxjs';
import { envConfig } from '../../../configs/multitenant-config';
import { LoginAuthService } from '../../../login/login-auth.service';
import { NAVIGATION } from './session-idle-constants';
import { GetScopes } from 'src/app/configs/msal-config';
import { NotificationService } from 'eycomponentlibrary';
import { LoginService } from 'src/app/login/login.service';
import { AppService } from '../../services/app.service';
import { ADL, MultiLoginUrlSessionKey, msalAuth, otpAuth, stateTokenSessionVarKey } from '../../constants/common.constants';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-session-idle',
  templateUrl: './session-idle.component.html',
  styleUrls: ['./session-idle.component.scss']
})
export class SessionIdleComponent implements OnInit, OnDestroy {

  public idleCounter: any;
  private activeTimer: boolean = true;
  private setIdleTime: any = envConfig['setIdleTime'];
  private setIdleTimeOut: any = envConfig['setIdleTimeOut'];
  private setKeepAlivePing: any = envConfig['setKeepAlivePing'];

  private subscriptionHolder: Subscription = new Subscription();

  public showPopup: boolean = false;

  constructor(
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    private loginService: LoginAuthService,
    private dpInjector: Injector,
    private notificationSvc: NotificationService,
    private otpLoginSvc: LoginService,
    private appSvc: AppService
  ) {
    this.idle.setIdle(this.setIdleTime);
    this.idle.setTimeout(this.setIdleTimeOut);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.setKeepaliveEnabled(true);
    this.keepalive.interval(this.setKeepAlivePing);
    this.subscriptionHolder.add(
      this.keepalive.onPing.subscribe(() => {
        // console.log("%c Polled at -->", "background: green;color:white;padding: 2px;", new Date().toLocaleTimeString());
      })
    );
  }

  ngOnInit(): void {
    this.idle.watch();
    this.subscriptionHolder.add(
      this.idle.onIdleStart.subscribe(() => {
        this.activeTimer = true;
        this.idle.clearInterrupts();
        this.showPopup = true;
      })
    );

    this.subscriptionHolder.add(
      this.idle.onIdleEnd.subscribe(() => {
        this.activeTimer = false;
      })
    );

    this.subscriptionHolder.add(
      this.idle.onTimeout.subscribe(() => {
        this.logOut();
      })
    );

    this.subscriptionHolder.add(
      this.idle.onTimeoutWarning.subscribe((c) => {
        this.idleCounter = c;
        this.idle.clearInterrupts();
      })
    );

    // stop idle check for login page
    this.stopIdlingOnLoginPage();
  }

  public stopIdlingOnLoginPage() {
    this.subscriptionHolder.add(this.router.events.pipe(filter(event => (event instanceof NavigationEnd)))
      .subscribe((routeData: any) => {
        if (routeData.urlAfterRedirects === NAVIGATION.BASE_URL || routeData.urlAfterRedirects === NAVIGATION.LOGIN_URL || routeData.urlAfterRedirects.indexOf('/login') > -1) {
          this.idle.stop();
          console.log("%c Polling Stopped", "background: red;color:white;padding: 2px;font-size:2em;");
        }
        else {
          this.idle.watch();
        }
      }));
  }

  public resetIdle() {
    var isOTP = sessionStorage.getItem('authmode');
    const msalService = this.dpInjector.get<MsalAuthService>(MsalAuthService);
    if (msalService !== undefined && isOTP && isOTP == msalAuth) {
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      const scopes = GetScopes();
      if (scopes !== undefined && scopes.length > 0) {
        msalService.getAccessTokenforINVP(scopes)
          .pipe(take(1))
          .subscribe({
            next: (token) => {
              if (token) {
                this.idle.watch();
              }
            }, error: (err) => {
              console.log(err);
              this.notificationSvc.warn("Session has timed out. Please re-login");
              setTimeout(() => {
                this.showPopup = false;
                this.idle.stop();
                this.router.navigate([NAVIGATION.LOGIN_URL]);
              }, 1000);
            }
          });
      }
    }
    else if (isOTP && isOTP == otpAuth && this.appSvc.getOTPAccessToken != '') {      
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      this.idle.watch();
    } else {
      console.log('Idle reset failed, redirecting to login page');
      this.logOut();
    }
  }
  public onContinue() {
    console.log("Reset Idle");
    this.showPopup = false;
    this.resetIdle();
  }
  public logOut = () => {
    var isOTP = sessionStorage.getItem('authmode');
    if (isOTP && isOTP == otpAuth) {
      this.showPopup = false;
      this.idle.stop();
      const broadcastChannel = new BroadcastChannel('logout_channel');
      broadcastChannel.postMessage({action:'sessionidleLogoutOTP'});
      broadcastChannel.close();     
    }
    else {      
      this.loginService.logOut(true,true);  
      setTimeout(() => {
        this.showPopup = false;
        this.idle.stop(); 
      }, 2000);      
    }
  }

  private getDecodedToken(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(
          (c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        )
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  private checkTokenExpiry(token: string): boolean {
    if (token == '') return true;
    const jwtJson = this.getDecodedToken(token);
    var date = new Date();
    let dateTime = (date.getTime() / 1000);
    let res = jwtJson.exp < dateTime;
    return res;
  }

  ngOnDestroy(): void {
    this.subscriptionHolder.unsubscribe();
  }
}
