import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginService } from 'src/app/login/login.service';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-investor-summary',
  templateUrl: './investor-summary.component.html',
  styleUrls: ['./investor-summary.component.scss']
})
export class InvestorSummaryComponent implements OnInit {

  @Input() layout: Array<LayoutTemplate>;
  @Input() data: Array<DataTemplate>;
  @Input() withholding: boolean;
  @Input() recordsPerPage = 10;
  @Input() activePage: number = 1;
  @Input() isPartnerDataGrouped: boolean = false;
  @Output() onPageChange: EventEmitter<any> = new EventEmitter();

  public pages: number[] = [];
  totalRecords: number;
  originalList: any;
  pageCount: number;
  pagedData: any;
  isPristine: boolean = false;
  clientName: string;
  enableAddressReview: boolean = false;
  addressReviewTxt: string;
  configSubscription: Subject<boolean> = new Subject();
  @ViewChild('textoverflow') widgetsContent: ElementRef;
  @ViewChild('listitem') listitem: ElementRef;

  constructor(private loginSvc: LoginService,
    private appSvc: AppService) { }

  ngOnInit(): void {
    this.clientName = this.appSvc?.Client?.clientName;
    this.getConfigValues();
  }

  getConfigValues() {
    this.appSvc.getConfiguration
      .pipe(takeUntil(this.configSubscription))
      .subscribe(configs => {
        if (configs && configs.length > 0) {
          this.enableAddressReview = configs?.find((x) => x.value.name === "compositeAddressReviewEnabled")?.value?.value;
          this.addressReviewTxt = (configs?.find((x) => x.value.name === "compositeAddressReviewText")?.value?.value)?.replace('@clientName', this.clientName);
        }
      });
  }

  ngOnChanges(): any {
    this.totalRecords = this.data?.length;
    this.pageCount = this.getPageCount();
    this.pages = this.getArrayOfPage(this.pageCount);
    this.totalRecords = this.data?.length;
    if (this.totalRecords <= 10 || this.isPartnerDataGrouped) {
      this.activePage = 1;
    }
    this.pagedData = this.data ? [...this.data].splice((this.activePage - 1) * this.recordsPerPage, this.recordsPerPage) : [];
    this.onPageChange.emit({ pagedData: this.pagedData, activePage: this.activePage });
  }

  private getPageCount(): number {
    let totalPage = 0;
    if (this.totalRecords > 0 && this.recordsPerPage > 0) {
      const pageCount = this.totalRecords / this.recordsPerPage;
      const roundedPageCount = Math.floor(pageCount);
      totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }

    return totalPage;
  }

  private getArrayOfPage(pageCount: number): number[] {
    const pageArray = [];
    if (pageCount > 0) {
      for (let i = 1; i <= pageCount; i++) {
        pageArray.push(i);
      }
    }

    return pageArray;
  }
  scrollLeft(pageNumber: number){
    
    if (pageNumber >= 1 && pageNumber <= this.data?.length) {
      this.activePage = pageNumber;
      this.pagedData = [...this.data].splice((this.activePage - 1) * this.recordsPerPage, this.recordsPerPage);
      this.onPageChange.emit({ pagedData: this.pagedData, activePage: this.activePage });
    }
    
      this.widgetsContent.nativeElement.scrollLeft = this.widgetsContent.nativeElement.scrollLeft -=this.listitem.nativeElement.clientWidth+17;
  
  }

  scrollRight(pageNumber: number){
    
    if (pageNumber >= 1 && pageNumber <= this.data?.length) {
      this.activePage = pageNumber;
      this.pagedData = [...this.data].splice((this.activePage - 1) * this.recordsPerPage, this.recordsPerPage);
      this.onPageChange.emit({ pagedData: this.pagedData, activePage: this.activePage });
    }
   
      this.widgetsContent.nativeElement.scrollLeft = this.widgetsContent.nativeElement.scrollLeft += this.listitem.nativeElement.clientWidth+15;
   
  }
  onClickPage(pageNumber: number): void {
    if (pageNumber >= 1 && pageNumber <= this.data?.length) {
      this.activePage = pageNumber;
      this.pagedData = [...this.data].splice((this.activePage - 1) * this.recordsPerPage, this.recordsPerPage);
      this.onPageChange.emit({ pagedData: this.pagedData, activePage: this.activePage });
    }
  }

  trackByValue(rowIndex: number, colIndex: number, data: DataTemplate) {
    if (this.layout[rowIndex].trackByProperty) {
      return `${rowIndex}${colIndex}-${data.value[this.layout[rowIndex].trackByProperty]}`;
    }
    return `${rowIndex}${colIndex}`;
  }

  ngOnDestroy() {
    this.configSubscription.next(true);
    this.configSubscription.unsubscribe();
  }
}

export class LayoutTemplate {
  rowLabel: string; rowControl: TemplateRef<any>; isHeader?: boolean; trackByProperty?: string;
  rowClass?: string; headerClass?: string ; rowLabel1? : string ; isSubHeader?: boolean; states? : any;
}
export class DataTemplate { value: { [rowID: string]: any }; }
