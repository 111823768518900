import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { IJurisdiction, IWithHoldingAllowedJur } from '../models/iJurisdiction';
import { UsStates } from '../../../assets/data/us-states';
import { IClient } from '../models/iClient';
import { ITaxYear } from '../models/iTaxYear';
import { IPartner } from '../models/iPartner';
import { IPartner as IWithholdingPartner } from '../../service-offering/withholding-service/models/partner-details.models'
import { EventEmitter } from '@angular/core';
import { IConfiguration, IServiceConfiguration } from '../models/iConfiguration';
import { UserRole } from '../models/eUserRole';
import { Contact } from '../models/iContact';
import { IEntity } from '../models/iEntity';
import { IServiceOffering } from '../models/iServiceOffering.model';
import {IBrowserVersion, IBrowsers} from '../models/iBrowserVersion'
import { HttpBackend, HttpClient } from '@angular/common/http';
import { BrowserSupportUrl, msalAuth, otpAuth } from '../constants/common.constants';
import { IConsolidatedPartnerInfo } from '../models/iConsolidatedPartnerInfo';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private _partnerInContext = new BehaviorSubject<IPartner>(undefined);
  private _whPartnerInContext = new BehaviorSubject<IWithholdingPartner>(undefined);
  private _client: IClient;
  private _states: IJurisdiction[];
  private _isCPA: boolean;
  private _currentTaxYear: ITaxYear;
  private _menus = new BehaviorSubject<string[]>(undefined);
  private _clientLoaded = new BehaviorSubject<boolean>(false);
  private _remainingSurveysCount: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private _configuration: BehaviorSubject<IServiceConfiguration[]> = new BehaviorSubject<IServiceConfiguration[]>(null);
  private _services: BehaviorSubject<IServiceOffering[]> = new BehaviorSubject<IServiceOffering[]>(null);
  private _instructionsContent: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _submitContent: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _instructionsContentV2: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _submitContentV2: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _showFAQ: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isTaxYearAvailable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  private _userRole = new BehaviorSubject<UserRole>(null);
  private _investorContactId: BehaviorSubject<string> = new BehaviorSubject(undefined);
  private _allInvestorContacts: Array<Contact>;
  private _withholdingSubmitContent: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _withholdingInstructionsContent: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _compositeElectedJur: IJurisdiction[];
  private _compositeEntities: IEntity[];
  private _uniqueInvestorContactId: BehaviorSubject<string> = new BehaviorSubject(undefined);
  private _browserId: BehaviorSubject<string> = new BehaviorSubject(null);
  private _clientOS: BehaviorSubject<any> = new BehaviorSubject(null);
  private _ceFlowOptedByClient: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _isCEFlowV2OptedByClient: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  private _stateSourceIncomeContent: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _compositeElectionOptionHeaderContent: BehaviorSubject<string> = new BehaviorSubject<string>(null);  
  private _consolidatedpartnerInContext = new BehaviorSubject<IConsolidatedPartnerInfo>(undefined);


  //store required when returning from docusign  
  private _partnerId = new BehaviorSubject<string>(null);
  private _isDocusignRedirect = new BehaviorSubject<boolean>(false);
  private _docusignFileName = new BehaviorSubject<string>(null);
  //will be used for client admin to store investor contact id
  private _docusignContactId = new BehaviorSubject<string>(null);

  //store required for calculating withholding completed survey
  private _totalWHSurveyCount = new BehaviorSubject<number>(0);

  private _callFromWHDocScreen = new BehaviorSubject<boolean>(false);

  private _isBackbtnPressed : boolean = false;

  private _allowedStatesForWH : IWithHoldingAllowedJur[];
  private _isCEFirstService:boolean;

    //OTP Login
  private _isOTPLogin: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _otpAccessToken: string = '';
  private _otpStateToken: string = '';
  private _secondfactorbackclick: boolean = false;
  private _otpResendBtnDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);  
    //OTP Login

  // UI rewamp
  private _isCEEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);  
  private _isIPEnabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);  

  constructor(private httpBackend: HttpBackend, private httpClient: HttpClient) {
    this.getStates();
  }

    //OTP Login
  public get getOTPAccessToken(): string {
    if(this._otpAccessToken == ''){
      var accessToken = sessionStorage.getItem('otpaccesstoken');
      if(accessToken)
        this._otpAccessToken = accessToken;
    }
    return this._otpAccessToken;
  }
  public setOTPAccessToken(otpAccessToken: string) {
    this._otpAccessToken = otpAccessToken;
  }
  
  public getOTPStateToken():string{
    return this._otpStateToken;
  }
  
  public setOTPStateToken(otpStateToken: string){
     this._otpStateToken = otpStateToken;
  }

  public Is2ndFactorBackbuttonclicked():boolean{
    return this._secondfactorbackclick;
  }
  
  public setIs2ndFactorBackbuttonclicked(secondfactorbackclicked: boolean){
     this._secondfactorbackclick = secondfactorbackclicked;
  }
  public isOTPResendBtnDisabled(): Observable<boolean> {
    return this._otpResendBtnDisabled.asObservable();
  }

  public setOTPResendBtnDisabled(isResendDisabled: boolean) {
    this._otpResendBtnDisabled.next(isResendDisabled);
  }
    //OTP Login
  public get PartnerInContext(): Observable<IPartner> {
    return this._partnerInContext.asObservable();
  }

  public get ConsolidatedPartnerInContext(): Observable<IConsolidatedPartnerInfo> {
    return this._consolidatedpartnerInContext.asObservable();
  }

  public get whPartnerInContext(): Observable<IWithholdingPartner> {
    return this._whPartnerInContext.asObservable();
  }

  public get Client(): IClient {
    if(this._client == null || this._client?.id == ''){
      var dcid = sessionStorage.getItem('dcid');
      if(dcid)
        this._client = {id:dcid, clientName: ''};
  }
  return this._client;
}

  public get States(): IJurisdiction[] {
    return this._states;
  }

  public get isCPA(): boolean {
    return this._isCPA;
  }

  public get CurrentTaxYear(): ITaxYear {
    return this._currentTaxYear;
  }

  public get Menus(): Observable<string[]> {
    return this._menus.asObservable();
  }

  public get isClientLoaded(): Observable<boolean> {
    return this._clientLoaded.asObservable();
  }
  public get getRemainingSurveysCount(): Observable<number> {
    return this._remainingSurveysCount.asObservable();
  }

  public get getConfiguration(): Observable<IServiceConfiguration[]> {
    return this._configuration.asObservable();
  }

  public get getServices(): Observable<IServiceOffering[]> {
    return this._services.asObservable();
  }

  public get getInstructionContent(): Observable<string> {
    return this._instructionsContent.asObservable();
  }

  public get getInstructionContentV2(): Observable<string> {
    return this._instructionsContentV2.asObservable();
  }

  public get getCompositeElectionOptionHeaderContent(): Observable<string> {
    return this._compositeElectionOptionHeaderContent.asObservable();
  }  

  public get getStateSourceIncomeContent(): Observable<string> {
    return this._stateSourceIncomeContent.asObservable();
  }
  

  public get getWithholdingInstructionContent(): Observable<string> {
    return this._withholdingInstructionsContent.asObservable();
  }

  public get getSubmitContent(): Observable<string> {
    return this._submitContent.asObservable();
  }

  public get getSubmitContentV2(): Observable<string> {
    return this._submitContentV2.asObservable();
  }
  public get getWithholdingSubmitContent(): Observable<string> {
    return this._withholdingSubmitContent.asObservable();
  }

  public get getShowFAQ(): Observable<boolean> {
    return this._showFAQ.asObservable();
  }

  public get GetUserRole(): Observable<UserRole> {
    return this._userRole.asObservable();
  }

  public get InvestorContactId(): BehaviorSubject<string> {
    return this._investorContactId;
  }
  /**Returns a boolean observable indicationg if current tax year is open or closed. 
   * @description "Open": if system date lies b/w current tax year start date and end date.
   * "Closed": if system date lies outside of current tax year start date and end date.
  */
  public get isTaxYearAvailable(): Observable<boolean> {
    return this._isTaxYearAvailable.asObservable();
  }

  public get AllInvestorContacts(): Array<Contact> {
    return this._allInvestorContacts;
  }
  public get isBackbtnPressed(): boolean {
    return this._isBackbtnPressed;
  }

  public get getCompositeFlowOptedByClient() : Observable<string> {
    return this._ceFlowOptedByClient;
  } 

  public get isCompositeFlowV2OptedByClient() : Observable<boolean> {
     this._isCEFlowV2OptedByClient = new BehaviorSubject<boolean>(this._ceFlowOptedByClient?.value?.toString() == "v2" ? true : false);
     return  this._isCEFlowV2OptedByClient;
  } 

  public get allowedStatesForWH(): IWithHoldingAllowedJur[] {    
    return this._allowedStatesForWH;
  }

  public get isCEFirstService(): boolean {    
    return this._isCEFirstService;
  }

  public setPartnerInContext(partner: IPartner) {
    this._partnerInContext.next(partner);
  }

  public setConsolidatedPartnerInContext(partner: IConsolidatedPartnerInfo) {
    this._consolidatedpartnerInContext.next(partner);
  }

  public setWHPartnerInContext(partner: IWithholdingPartner) {
    this._whPartnerInContext.next(partner);
  }

  public setIsCPA(cpa: boolean) {
    this._isCPA = cpa;
  }

  public setInvestorContactId(contactId: string) {
    this._investorContactId.next(contactId);
  }

  public setUniqueInvestorContactId(contactId: string) {
    this._uniqueInvestorContactId.next(contactId);
  }

  public setCurrentTaxYear(year: ITaxYear): void {
    this._currentTaxYear = year;
    let presentDate = new Date();
    this._isTaxYearAvailable.next(this.getEquivalentDateValue(year.startDate) <= this.getEquivalentDateValue(presentDate) &&
      this.getEquivalentDateValue(presentDate) <= this.getEquivalentDateValue(year.endDate));
  }

  public setMenu(menus: string[]) {
    this._menus.next(menus);
  }

  public setClient(client: IClient) {
    this._client = client;
    if (client)
      this._clientLoaded.next(true);
  }

  private getStates() {
    this._states = UsStates.features.map(s => s.properties);
  }

  public set setRemainingSurveysCount(remainingSurveysCount: number) {
    this._remainingSurveysCount.next(remainingSurveysCount);
  }
  public setConfiguration(configurations: IServiceConfiguration[]) {
    this._configuration.next(configurations);
  }

  public setServices(services: IServiceOffering[]) {
    this._services.next(services);
  }

  public setInstructionsContent(instructionsContent: string) {
    this._instructionsContent.next(instructionsContent);
  }

  public setInstructionsContentV2(instructionsContent: string) {
    this._instructionsContentV2.next(instructionsContent);
  }

  public setStateSourceIncomeContent(stateSourceIncomeContent: string) {
    this._stateSourceIncomeContent.next(stateSourceIncomeContent);
  }

  public setCompositeElectionOptionHeaderContent(compositeElectionOptionHeaderContent: string) {
    this._compositeElectionOptionHeaderContent.next(compositeElectionOptionHeaderContent);
  }

  public setWithholdingInstructionsContent(instructionsContent: string) {
    this._withholdingInstructionsContent.next(instructionsContent);
  }

  public setSubmitContent(submitContent: string) {
    this._submitContent.next(submitContent);
  }
  public setSubmitContentV2(submitContent: string) {
    this._submitContentV2.next(submitContent);
  }
  public setWithholdingSubmitContent(submitContent: string) {
    this._withholdingSubmitContent.next(submitContent);
  }

  public setShowFAQ(show: boolean) {
    this._showFAQ.next(show);
  }

  public setUserRole(userRole: UserRole) {
    this._userRole.next(userRole);
  }

  public setCompositeFlowOpted(ceFlow: string) {
    this._ceFlowOptedByClient.next(ceFlow);
  }

  public setInvestorContacts(contacts: Array<Contact>) {
    this._allInvestorContacts = contacts;
  }

  public setIsBackbtnPressed(isBackbtnPressed: boolean) {
    this._isBackbtnPressed = isBackbtnPressed;
  }

  public setAllowwedStateForWH(allowedStatesForWH: IWithHoldingAllowedJur[]) {    
    this._allowedStatesForWH = allowedStatesForWH;
  }

  public setisSCPFirstService(isSCPFirstService: boolean) {    
    this._isCEFirstService= isSCPFirstService;
  }

  public flushState() {
    this._partnerInContext = new BehaviorSubject<IPartner>(undefined);
    this._whPartnerInContext = new BehaviorSubject<IWithholdingPartner>(undefined);
    this._client = undefined;
    this._states = undefined;
    this._isCPA = undefined;
    this._currentTaxYear = undefined;
    this._menus = undefined;
    this._isTaxYearAvailable = new BehaviorSubject<boolean>(undefined);
    this._investorContactId = new BehaviorSubject<string>(undefined);
    this._allInvestorContacts = undefined;

    this._partnerId = new BehaviorSubject<string>(null);
    this._isDocusignRedirect = new BehaviorSubject<boolean>(false);
    this._docusignFileName = new BehaviorSubject<string>(null);
    this._docusignContactId = new BehaviorSubject<string>(null);

    this._totalWHSurveyCount = new BehaviorSubject<number>(0);
    this._callFromWHDocScreen = new BehaviorSubject<boolean>(false);

    this._isBackbtnPressed = false;
    
    this._isCEFlowV2OptedByClient = new BehaviorSubject<boolean>(undefined);
    
    this._consolidatedpartnerInContext = new BehaviorSubject<IConsolidatedPartnerInfo>(undefined);
    
  }

  private getEquivalentDateValue(date: Date): number {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1);
    const dateOfMonth = date.getDate();

    return +`${year}${month < 10 ? ('0' + month) : month}${dateOfMonth < 10 ? ('0' + dateOfMonth) : dateOfMonth}`;
  }

  public getEquivalentDateStringValue(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1);
    const dateOfMonth = date.getDate();
    return `${year}-${month < 10 ? ('0' + month) : month}-${dateOfMonth < 10 ? ('0' + dateOfMonth) : dateOfMonth}`;   
  }

  public setCompositeElectedJur(jurisdictions: IJurisdiction[]) {
    this._compositeElectedJur = jurisdictions;
  }
  public get getCompositeElectedJur(): IJurisdiction[] {
    return this._compositeElectedJur;
  }
  public setCompositeEntities(entities: IEntity[]) {
    this._compositeEntities = entities;
  }
  public get getCompositeEntities(): IEntity[] {
    return this._compositeEntities;
  }
  public get UniqueInvestorContactId(): BehaviorSubject<string> {
    return this._uniqueInvestorContactId;
  }

  //#region Docusign app storage
  public get getDocusignPartnerIdRedirect(): string {
    return this._partnerId.value;
  }

  public setDocusignPartnerIdRedirect(partnerId:string) {
    this._partnerId.next(partnerId);
  }

  public get getDocusignFile(): string {
    return this._docusignFileName.value;
  }

  public setDocusignFile(fileName: string) {
    this._docusignFileName.next(fileName);
  }

  public get getDocusignContactId(): string {
    return this._docusignContactId.value;
  }

  public setDocusignContactId(contactId: string) {
    this._docusignContactId.next(contactId);
  }

  
  public get getDocusignRedirectVal(): boolean {
    return this._isDocusignRedirect.value;
  }

  public setDocusignRedirectVal(val: boolean) {
    this._isDocusignRedirect.next(val);
  }  
  //#endregion

  public get totalSurveyCounts(): number {
    return this._totalWHSurveyCount.value;
  }

  public set setTotalSurveyCounts(surveySumiitedCount: number) {
    this._totalWHSurveyCount.next(surveySumiitedCount);
  }

  public get callFromWhDoc(): boolean {
    return this._callFromWHDocScreen.value;
  }

  public set setCallFromWhDoc(callFromWHDoc: boolean) {
    this._callFromWHDocScreen.next(callFromWHDoc);
  }

  public get browserId(): string{
    return this._browserId.value;
  }

  public setBrowserId(id: string) {
    this._browserId.next(id);
  }

  public get clientOS(): any {
    return this._clientOS.value;
  }

  public setClientOS(os: any) {
    return this._clientOS.next(os);
  }

  /**
 * Method will return updated version of browsers.
 */
  public getUpdatedBrowserInfromation(query: string): Observable<IBrowserVersion>{
    return new HttpClient(this.httpBackend).get<IBrowserVersion>(`${BrowserSupportUrl}${query}`);
  }

  public getMachineOSName() {
    let OSName;
    if (navigator.userAgent.indexOf("Win") != -1) return OSName = "Windows";
    else if (navigator.userAgent.indexOf("like Mac") != -1) return OSName = "iOS";
    else if (navigator.userAgent.indexOf("Mac") != -1) return OSName = "Macintosh";
    else if (navigator.userAgent.indexOf("Android") != -1) return OSName = "Android";
    else return false;
  }

  public detectBrowserVersion(){
    var userAgent = navigator.userAgent, tem, 
    matchTest = userAgent.match(/(opera|chrome|CriOS|FxiOS|EdgiOS|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(matchTest[1])){
        tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || [];
        return 'IE '+(tem[1] || '');
    }
    if(matchTest[1]=== 'Chrome'){
        tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    matchTest= matchTest[2]? [matchTest[1], matchTest[2]]: [navigator.appName, navigator.appVersion, '-?'];
    tem= userAgent.match(/version\/(\d+)/i)
    if(tem != null && matchTest[0] != 'EdgiOS') matchTest.splice(1, 1, tem[1]);
    return matchTest.join(' ');
  }

  public getQuery(browserId: string) {
    let query: string;
    if(browserId === 'and_chr') query ='3 ChromeAndroid';
    else if(browserId.toLocaleLowerCase() === 'and_ff') query = '3 FirefoxAndroid';
    else if(browserId.toLocaleLowerCase() === 'chrome') query = '3 Chrome';
    else if(browserId.toLocaleLowerCase() === 'firefox') query = '3 Firefox';
    else if(browserId.toLocaleLowerCase() === 'edge') query = '3 Edge';
    else if(browserId.toLocaleLowerCase() === 'ios_saf') query = '2 iOS';
    else if(browserId.toLocaleLowerCase() === 'safari') query = '2 Safari';
    else query = browserId;
    return `Last ${query} major versions`;
  }

  public getBrowserId(browser: string, OSName: any) {
    let brwsrID;
    if(OSName === 'Android'){
      if(browser === 'Chrome') return brwsrID = 'and_chr';
      else if(browser === 'Firefox') return brwsrID = 'and_ff';
      else return browser
    }
    else if(OSName === 'Windows'){
      if(browser === 'Chrome') return brwsrID = 'chrome';
      if(browser === 'Firefox') return brwsrID = 'firefox';
      if(browser === 'Edge') return brwsrID = 'edge';
    }
    else if(OSName === 'iOS'){
      if(browser === 'CriOS') return brwsrID = 'chrome';
      if(browser === 'FxiOS') return brwsrID = 'firefox';
      if(browser === 'EdgiOS') return brwsrID = 'edge';
      if(browser === 'Safari') return brwsrID = 'ios_saf';
    }
    else if(OSName === 'Macintosh'){
      if(browser === 'Chrome') return brwsrID = 'chrome';
      if(browser === 'Firefox') return brwsrID = 'firefox';
      if(browser === 'Edge') return brwsrID = 'edge';
      if(browser === 'Safari') return brwsrID = 'safari';
    } else return browser;
  }

  public isBrowserSupported(clientBrwsrVrsn,stblBrwsrVrsn, browserName) {
    let supportedVersions;
    if(clientBrwsrVrsn > stblBrwsrVrsn) {
      return false;
    }
    if(clientBrwsrVrsn === stblBrwsrVrsn) {
      return false;
    }
    if(clientBrwsrVrsn < stblBrwsrVrsn) {
      if(browserName === 'Safari') {
        supportedVersions = this.versionsRange(stblBrwsrVrsn, stblBrwsrVrsn - 1, 1);
      } else {
        supportedVersions = this.versionsRange(stblBrwsrVrsn, stblBrwsrVrsn - 2, 1);
      }
      if(supportedVersions.indexOf(clientBrwsrVrsn) == -1) {
        return true;
      } else{
        return false;
      }
    }
  }

  private versionsRange(start, stop, step) {
    return Array.from({ length: (start - stop) / step + 1 },(value, index) => start - index * step);
  }

  getLatestVesionQuery(clientOSName: string){
    let toDisplayVesrionQuery;
      if(clientOSName === 'Android') {
        toDisplayVesrionQuery = 'last 1 and_chr major version'+','+'last 1 and_ff major version'+','+'last 1 edge major version';
      } else if(clientOSName === 'Windows'){
        toDisplayVesrionQuery = 'last 1 chrome major version'+','+'last 1 firefox major version'+','+'last 1 edge major version';
      } else if(clientOSName === 'iOS'){
        toDisplayVesrionQuery = 'last 1 chrome major version'+','+'last 1 firefox major version'+','+'last 1 edge major version'+','+'last 1 ios_saf major version';
      }else if(clientOSName === 'Macintosh'){
        toDisplayVesrionQuery = 'last 1 chrome major version'+','+'last 1 firefox major version'+','+'last 1 edge major version'+','+'last 1 safari major version';
      }

    return toDisplayVesrionQuery;
  }

  public getAllBrowserLatestandNameObject(browserObj: IBrowserVersion){
    let displayObject:any = [];
    browserObj.browsers.forEach(x => {
      let obj = {name: this.officialBrowserName(x.id), version: this.supportedBrowserVersionToDisplay(x.id,Math.trunc(+Object.keys(x.versions)[0]))}
      displayObject.push(obj);
    });
    return displayObject;
  }

  private supportedBrowserVersionToDisplay(browser, version){
    if(browser === 'ios_saf' || browser === 'safari') return version - 1;
    else if(browser === 'edge') return version - 2;
    else if(browser === 'and_chr' || browser === 'chrome') return version - 2;
    else if(browser === 'and_ff' || browser === 'firefox') return version - 2;
  }

  private officialBrowserName(browser){
    if(browser === 'ios_saf' || browser === 'safari') return 'Safari';
    else if(browser === 'edge') return 'Microsoft Edge';
    else if(browser === 'and_chr' || browser === 'chrome') return 'Google Chrome';
    else if(browser === 'and_ff' || browser === 'firefox') return 'Mozila Firefox';
  }

  public setAuthMode(isOTP: boolean) {
    isOTP ? sessionStorage.setItem('authmode', otpAuth) : sessionStorage.setItem('authmode', msalAuth);
  }
  
  public setOTPToken(OTPAccessToken: string) {
    if (OTPAccessToken) {
      sessionStorage.setItem('otpaccesstoken', OTPAccessToken);
      this.setOTPAccessToken(OTPAccessToken);
    }
  }

  public isCEEnabled(): Observable<boolean> {
    return this._isCEEnabled.asObservable();
  }

  public setCEEnabled(isCEEnabled: boolean) {
    this._isCEEnabled.next(isCEEnabled);
  }

  public isIPEnabled(): Observable<boolean> {
    return this._isIPEnabled.asObservable();
  }

  public setIPEnabled(isIPEnabled: boolean) {
    this._isIPEnabled.next(isIPEnabled);
  }
}
