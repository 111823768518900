import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, InjectionToken, Injector, NgZone, Provider } from '@angular/core';
import { SESSION_STORAGE } from '../../configs/common-config';
import { MSALConfigFactory, MSALAngularConfigFactory, GetScopes, MSALGuardConfigFactory, MSALInterceptorConfigFactory, ExtraQueryParameters } from '../../configs/msal-config';
import { Router } from '@angular/router';
import { envConfig, getExternalClient, getInternalClient, getTenantType, setExternalTenantType, setInternalTenantType } from 'src/app/configs/multitenant-config';
import { AuthenticationResult, InteractionStatus, RedirectRequest, StringUtils } from '@azure/msal-browser';
import { defer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MsalInterceptor, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuard as AuthMsalGuard, MsalBroadcastService as AuthMsalBroadcastService } from '@azure/msal-angular';
import { RaptorUiLoggerService } from 'raptor-appinsightlogger-ui';
import { MsalSkipInterceptor } from 'src/app/interceptor/msal-skip-interceptor';

@Injectable({
  providedIn: 'root'
})
export class MsalAuthService {
  constructor(
    private appInsight :RaptorUiLoggerService,
    private ngZone: NgZone,
    private dpInjector: Injector,
    private router: Router,
    private broadCastService: AuthMsalBroadcastService,
    private msalService: MsalService) {
  }

  public loginRedirect() {
    var msalService = this.dpInjector.get(MSAL_SERVICE);
    this.broadCastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe(() => {
        if (msalService !== undefined) {
          const msalGuardConfig = this.dpInjector.get(MSAL_GUARD_CONFIG) as any;      
          if (msalGuardConfig.authRequest) {
            msalService.loginRedirect({ ...msalGuardConfig.authRequest, extraQueryParameters: ExtraQueryParameters() } as RedirectRequest);
          } else {
            msalService.loginRedirect({ scopes: GetScopes(), extraQueryParameters: ExtraQueryParameters() });
          }
        }
      })

  }

  public async setAuthConfig() {
    const msalService = this.dpInjector.get(MSAL_SERVICE);
    if (msalService !== undefined) {
      this.appInsight.logEvent('InvestorPortal_UILogs--Fetch configuration for MSAL started');
      msalService.instance = MSALConfigFactory();
      await msalService.instance.initialize();      
    }
    
  }

  public checkLoginSuccess() {
    const msalService = this.dpInjector.get(MSAL_SERVICE);
    if (msalService !== undefined) {
      msalService.handleRedirectObservable().subscribe({
        next: (result: AuthenticationResult) => {
          if (result) {
            msalService.instance.setActiveAccount(result.account);
            this.appInsight.logEvent('msalService.handleRedirectObservable() success for ' + result?.account?.username);
          }
          else {
            console.error('Token generation and Redirect Error: ');
            this.appInsight.logEvent('Token generation and Redirect Error: ');
            return;
          }
        },
        error: (authError: any) => {
          console.error('Token generation Error: ', authError.errorMessage);
          this.appInsight.logException(authError);
          return;
        }
      });
    }
  }

  public setTenantType(msalService: any) {
    if(envConfig.enableSingleTenant){
      if (msalService.instance.getActiveAccount().idToken?.aud == getInternalClient()) {
        if (!!msalService.instance.getActiveAccount() && sessionStorage.getItem(SESSION_STORAGE.LOGIN_INITIATED) == null && sessionStorage.getItem(SESSION_STORAGE.TENANT) == null) {
        setInternalTenantType();
      }
    }
    }
    else {
      if (!!msalService.instance.getActiveAccount() && sessionStorage.getItem(SESSION_STORAGE.LOGIN_INITIATED) == null && sessionStorage.getItem(SESSION_STORAGE.TENANT) == null) {
        if (msalService.instance.getActiveAccount().idToken?.aud == getInternalClient()) {
          setInternalTenantType();
        }
        else if (msalService.instance.getActiveAccount().idToken?.aud == getExternalClient()) {
          setExternalTenantType();
        }
      }
    }
  }

  public logout() {
    const msalService = this.dpInjector.get(MSAL_SERVICE);
   //msalService.logout();
   //adding the code to logout properly from safari 
    const account = msalService.instance.getActiveAccount();
    msalService.logout({ account: account });
  }

  public getAccessTokenforINVP(mfeScope: string[]) {
    const msalService = this.dpInjector.get(MSAL_SERVICE);
    return this.ngZone.run(() => {
      return defer(() => msalService.acquireTokenSilent({ scopes: mfeScope })).pipe(map((x: any) => {
        return x.tokenType === 'id_token' ? x.idToken.rawIdToken : x.accessToken;
      }));
    });
  }
  public getScopesForEndPoint(endPoint: string): string[] {
    return this.ngZone.run(() => {
      const tenantType = getTenantType();
      const resourceMaps = envConfig.protectedResourceUri;
      let keyMatchesEndpoint: string = '';
      for (let j = 0; j < resourceMaps.length; j++) {
        const key = resourceMaps[j];
        if (StringUtils.matchPattern(key, endPoint)) {
          keyMatchesEndpoint = key;
          break;
        }
      };
      let endPointArray: string[] = [];
      if (keyMatchesEndpoint !== undefined) {
        endPointArray = envConfig[tenantType].scope;
      }
      return endPointArray;
    });
  }
}

export function MsalProviders(): Provider[] {
  const providers: Provider[] =
    [
      { provide: HTTP_INTERCEPTORS, useClass: MsalSkipInterceptor, multi: true },
      { provide: MSAL_INSTANCE, useFactory: MSALConfigFactory },
      { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
      { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
      { provide: MSAL_SERVICE, useClass: MsalService },
      MsalService,
      AuthMsalGuard,
      { provide: MSAL_BROADCAST_SERVICE, useClass: AuthMsalBroadcastService }
    ];
  return providers;
}

export const MSAL_SERVICE = new InjectionToken<MsalService>('msal_service');
export const MSAL_BROADCAST_SERVICE = new InjectionToken<AuthMsalBroadcastService>('msal_broadcast_service');
