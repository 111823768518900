import { Component, OnInit, NgZone, Inject, OnDestroy, Injector, ViewChild, HostListener, TemplateRef } from '@angular/core';
import { ThemeService } from './themes/theme.service';
import { NavigationService } from './shared/services/navigation.service';
import { LoginService } from './login/login.service';
import { filter, mergeMap, take, takeUntil } from 'rxjs/operators';
import { DropdownComponent, LoaderService, NotificationService } from 'eycomponentlibrary';
import { AppService } from './shared/services/app.service';
import { of, Subject, Subscription } from 'rxjs';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { APP_BASE_HREF, DOCUMENT } from '@angular/common';
import { UserRole } from './shared/models/eUserRole';
import { Contact } from './shared/models/iContact';
import { LandingService } from './service-offering/landing/landing.service';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Title } from '@angular/platform-browser';
import { IModal, ModalService } from './shared/services/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalAuthService } from './shared/services/msal-auth.service';
import { SESSION_STORAGE } from './configs/common-config';
import * as lite from 'caniuse-lite';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { IBrowserVersion, IBrowsers } from './shared/models/iBrowserVersion';
import { RaptorUiLoggerService } from 'raptor-appinsightlogger-ui';
import { environment } from 'src/environments/environment';
import { ADL, MultiLoginUrlSessionKey, otpAuth, stateTokenSessionVarKey } from './shared/constants/common.constants';
import { NAVIGATION } from './shared/components/session-idle/session-idle-constants';
import { IServiceConfiguration } from './shared';
import { IServiceOffering } from './shared/models/iServiceOffering.model';
import { COMPOSITE_CONFIGS } from './service-offering/composite-service/constants/composite-common.constants';
declare var EY_INVP_logOutUser: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Investor Portal';
  isIFrame = false;
  loggedIn = false;
  showLoaderIndicator = false;
  error = false;
  showBrowserSupportModal: boolean;
  $componentIsDestroyed: Subject<boolean> = new Subject<boolean>();
  showPartnerContacts = false;
  investorContacts: Contact[];
  selectedContact: string = '--Select--';
  selectedContactValueSpaces: string;
  selectedContactValue: string;
  uniqueSelectedContact: string;
  isCancelAvailable: boolean = false;
  faWarning = faExclamationTriangle;
  modalProperties: IModal;
  showModal: boolean;
  modalSubscription$: Subscription;
  userRole: UserRole;
  private isDocuSignRedirect: boolean = false;
  disableSelectButton: boolean = false;
  currentBrowser: string;
  currentBrowserVersion: number;
  minBrowserVersionReq: number;
  private readonly _destroying$ = new Subject<void>();
  private isOTP: boolean = false;
  private isV2UIEnabled: boolean = false;
  availableServices: IServiceOffering[] = [];

  @ViewChild("filterDropdown", { static: false }) filterDropdown: DropdownComponent;
  @ViewChild("sessionidleModal", { static: false }) sessionidleModal: TemplateRef<any>;
  constructor(
    private appInsight: RaptorUiLoggerService,
    private themeSvc: ThemeService,
    private navigationSvc: NavigationService,
    private loginSvc: LoginService,
    private appService: AppService,
    private ngZone: NgZone,
    public loaderService: LoaderService,
    public landingSvc: LandingService,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: HTMLDocument,
    @Inject(APP_BASE_HREF) clientID: string,
    private modalSvc: ModalService,
    private titleSvc: Title,
    private notifySvc: NotificationService,
    private dpInjector: Injector,
    private router: Router
  ) {
    clientID = clientID?.length > 1 ? clientID.substr(1, clientID.length - 1) : clientID;
    if (clientID && clientID.length > 1) {
      sessionStorage.setItem('cid', clientID);
      appInsight.logEvent('cid set in session storage--' + clientID);
    }
  }

  ngOnInit() {
    var isOTP = sessionStorage.getItem('authmode');
    this.isOTP = isOTP && isOTP == otpAuth
    this.listenToModalChanges();

    //check for query params //activated route for reading query params
    this.activatedRoute.queryParamMap.subscribe(
      (params) => {
        if (params && params.get("event") && params.get("partnerId")) {
          const partnerId = params.get("partnerId").trim();
          const fileName = params.get("fileName").trim().replace("%26", "&");
          const contactId: string = params.get("contactId") ? params.get("contactId").trim() : null;
          this.isDocuSignRedirect = true;
          this.appService.setDocusignRedirectVal(true);
          this.appService.setDocusignPartnerIdRedirect(partnerId);
          this.appService.setDocusignFile(fileName);

          if (contactId) {
            this.appService.setUniqueInvestorContactId(contactId);
            this.appService.setDocusignContactId(contactId.substring(0, contactId.indexOf('|')).trim());
          }
        }
      });

    this.loaderService.ShowLoader('primary').subscribe(x => {
      if (this.showLoaderIndicator !== x) {
        this.ngZone.run(() => this.showLoaderIndicator = x);
      }
    });
    this.isIFrame = window !== window.parent && !window.opener;
    const url = window.location.href;

    this.themeSvc.setThemeByClient();
    this.title = this.themeSvc.activeTheme.title ?? 'Investor Portal';
    this.titleSvc.setTitle(this.title);
    //----------------------------Commented for OTP to Work. MSAL Redirect---------------------------------
    if (!this.isOTP) {
      const msalService = this.dpInjector.get<MsalAuthService>(MsalAuthService);
      if (msalService !== undefined) {
        msalService.checkLoginSuccess();
      } else {
        console.log('Login check failed, service unavailable');
        this.appInsight.logEvent('Login check failed, service unavailable');
      }
      this.checkoutAccount();
      this.broadcastService.msalSubject$
        .pipe(
          // Optional filtering of events.
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          // Casting payload as AuthenticationResult to access account
          const payload = result.payload as AuthenticationResult;
          this.authService.instance.setActiveAccount(payload.account);
          this.checkoutAccount();
        });
    }
    else {
      var st = sessionStorage.getItem(stateTokenSessionVarKey);
      if (st) {
        this.appService.setOTPStateToken(st);
      }
      this.checkoutAccount();
    }
    //----------------------------Commented for OTP to Work. MSAL Redirect---------------------------------

    this.listenToLogout();
  }

  onLoginRedirect() {
    this.navigationSvc.logOut();
  }




  closeBrowserSupportModal() {
    this.showBrowserSupportModal = false;
  }

  selectInvestor() {
    this.uniqueSelectedContact = this.selectedContact;
    this.showPartnerContacts = false;
    this.selectedContact = this.selectedContact.substring(0, this.selectedContact.indexOf('|')).trim();
    if (this.selectedContact !== this.appService.InvestorContactId.value) {
      this.appService.setUniqueInvestorContactId(this.uniqueSelectedContact);
      this.appService.setInvestorContactId(this.selectedContact);
      if (this.isCancelAvailable) {
        this.redirectToServiceOffering();
        //this.navigationSvc.navigateToServiceOfferingPage();
      }
      this.navigationSvc.navigateToRedirectPage();
    }
  }

  redirectToServiceOffering() {
    this.isV2UIEnabled = false;
    this.loginSvc.getConfiguration(COMPOSITE_CONFIGS)
    .pipe(take(1))
    .subscribe((configuration: IServiceConfiguration[]) => {
      this.isV2UIEnabled = configuration?.find(x => x.value.name == 'NewUIEnabled')?.value?.value;
      if (this.isV2UIEnabled)
        this.navigationSvc.navigateToServiceOfferingPage(this.isV2UIEnabled);
      else
        this.navigationSvc.navigateToServiceOfferingPage(false);
    },
      (error) => {
        console.error(error);
        this.notifySvc.error("Failed to load configurations. Please refresh the page.");
      }
    );
  }



  contactInputBlurChanged(event) {
    if (!event?.value) {
      this.investorContacts = [...this.investorContacts];
      this.selectedContact = '--Select--';
    }

  }

  cancelSelection() {
    this.showPartnerContacts = false;
    this.selectedContact = this.appService.InvestorContactId.value;
  }

  ngOnDestroy() {
    this.$componentIsDestroyed.next(true);
    this.$componentIsDestroyed.unsubscribe();
    if (this.modalSubscription$ && !this.modalSubscription$.closed)
      this.modalSubscription$.unsubscribe();
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  private redirect() {
    // // to check for otp login in duplicate tabs, and restrict when clientId is not present
    const encryptedClientId = sessionStorage.getItem('cid');
    this.loginSvc.isAuthorizedUser(encryptedClientId).pipe(
      takeUntil(this.$componentIsDestroyed),
      mergeMap(authorizedUser => {
        if (authorizedUser !== undefined && authorizedUser) {
          this.appService.setClient(authorizedUser);
          return this.loginSvc.getUserRole();
        }
        return of(null);
      })
    ).subscribe(userRole => {
      if (userRole != undefined && userRole != null && userRole >= 0) {
        this.appInsight.logEvent('UserRole for ' + this.authService.instance.getActiveAccount()?.username + ' ' + userRole);
        // set isCPA in the state
        this.appService.setIsCPA(userRole == UserRole.CPA || userRole == UserRole.CPAInt);
        this.appService.setUserRole(userRole);

        if (!this.isDocuSignRedirect) {
          //to decide the navigation to old or new UI
          this.navigationSvc.navigateToRedirectPage();
        }
        else {
          //Setting available service when navigating back from docuSign so that CE and WH overlap logic works correctly
          this.landingSvc.getAvailableServices(this.appService.getDocusignContactId).pipe(take(1)).subscribe(services => {
            if (services) {
              window['availableServices'] = services;
              this.appService.setServices(services);
            }
          });
          this.navigationSvc.navigateToWithholdingDocsPage();
        }
      }
      else {
        this.appInsight.logEvent('UserRole for' + this.authService.instance.getActiveAccount()?.username + ' incorrect');
        this.notifySvc.error('You are not authorized to access this application!');
        this.ngZone.run(() => this.showLoaderIndicator = false);
      }
    },
      error => {
        this.appInsight.logException(error);
      });
  }

  private checkoutAccount() {
    if (!this.isOTP) {
      if (!!this.authService.instance.getActiveAccount() && !sessionStorage.getItem(SESSION_STORAGE.LOGIN_INITIATED) && !sessionStorage.getItem(SESSION_STORAGE.TENANT)) {
        const msalService = this.dpInjector.get<MsalAuthService>(MsalAuthService);
        if (msalService !== undefined) {
          msalService.setTenantType(this.authService);
        }
      }
      this.loggedIn = !!this.authService.instance.getActiveAccount() && !!sessionStorage.getItem(SESSION_STORAGE.LOGIN_INITIATED) && !!sessionStorage.getItem(SESSION_STORAGE.TENANT);
    }
    else {
      this.loggedIn = !!sessionStorage.getItem(SESSION_STORAGE.LOGIN_INITIATED);
    }
    this.initiateLogin();
  }

  private initiateLogin() {
    this.appInsight.logEvent('initiateLogin() started--logged in user ' + this.authService.instance.getActiveAccount()?.username + ' login status ' + this.loggedIn);
    if (!this.loggedIn) {
      var accessToken = sessionStorage.getItem('otpaccesstoken');
      if (accessToken) {
        this.revokeJWTTokenonRefresh();
      }
      if (window.location.pathname?.split('/')?.indexOf(btoa(otpAuth)) != -1 || sessionStorage.getItem(MultiLoginUrlSessionKey) === otpAuth) {
        this.navigationSvc.navigate_login('login', btoa(otpAuth));
      } else if (window.location.pathname?.split('/')?.indexOf(btoa(ADL)) != -1 || sessionStorage.getItem(MultiLoginUrlSessionKey) === ADL) {
        this.navigationSvc.navigate_login('login', btoa(ADL));
      } else {
        this.navigationSvc.navigate('login');
      }
    }
    else {
      var isOTP = sessionStorage.getItem('authmode');

      this.redirect();      // to check for otp login in duplicate tabs, and restrict when clientId is not present
      // if (isOTP && isOTP == otpAuth && !this.appService.Client?.id) {
      //   this.flushOTPKeys();
      // }  
      //Register callback for Client Admin
      this.appService.GetUserRole.pipe(takeUntil(this.$componentIsDestroyed)).subscribe(userRole => {
        if (userRole == UserRole.CA) {
          this.isCancelAvailable = false;
          this.landingSvc.getInvestorContacts().pipe(take(1)).subscribe(investorContacts => {
            this.appService.setInvestorContacts(investorContacts);
            this.investorContacts = [{ id: '--Select--', upn: '--Select/Search--' } as Contact, ...investorContacts];
            if (this.appService.getDocusignContactId) {
              this.showPartnerContacts = false;
              // this.selectedContact = this.appService.InvestorContactId.value;
              // this.appService.setInvestorContactId(this.appService.getDocusignContactId);

              this.uniqueSelectedContact = this.appService.UniqueInvestorContactId.value;
              this.selectedContact = this.uniqueSelectedContact;
              if (this.selectedContact !== this.appService.InvestorContactId.value) {
                this.appService.setUniqueInvestorContactId(this.uniqueSelectedContact);
              }
            }
            else {
              this.showPartnerContacts = true;
            }
          });
        }
        else if (userRole != null) {
          this.showPartnerContacts = false;
        }
      });
      this.landingSvc.ChangeContactEvent.pipe(takeUntil(this.$componentIsDestroyed)).subscribe(x => {
        this.showPartnerContacts = true;
        this.isCancelAvailable = true;
      });
    }

    if (this.document.getElementById('appFavicon')) {
      this.document.getElementById('appFavicon')
        .setAttribute('href', 'assets/images/' + this.themeSvc.activeTheme.properties['--favicon']);
    }
  }

  private getQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(
      new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  }

  /*private checkBrowserSupport() {
    // chrome & edge check
    this.showBrowserSupportModal = !((window['chrome'] != null && window.navigator.vendor.indexOf('Google Inc') > -1) ||
      window.navigator.userAgent.indexOf('Firefox') > -1 || // firefox check
      window.navigator.userAgent.indexOf('Safari') > -1); // Safari check
  }*/

  private listenToModalChanges() {
    this.modalSubscription$ = this.modalSvc.Listener.subscribe(changes => {
      this.showModal = changes.show;
      this.modalProperties = changes.modalProps;
    });
  }

  onGenericModalHide(flag) {
    this.showModal = flag;
    if (!flag) this.modalSvc.Response = false;
  }

  setGenericModalResponse(resp) {
    this.showModal = false;
    this.modalSvc.Response = resp;
  }


  disableButton() {
    var a = this.selectedContact
    if (this.selectedContact === '--Select--' || this.disableSelectButton)
      return true;
  }

  contactInputFieldChanged(evt) {
    let value = evt instanceof String ? evt : (<HTMLInputElement>evt.target)?.value;
    if (value?.length == 0)
      this.disableSelectButton = true;
    else
      this.disableSelectButton = false;
  }

  selectInputChange(selectedContactId) {
    if (selectedContactId == 0) {
      this.disableSelectButton = true;

    }
    else {
      this.disableSelectButton = false;
    }
    if (selectedContactId == '--Select--') {
      this.investorContacts = !this.investorContacts.some(x => x.id == '--Select--') ? [{ id: '--Select--', upn: '--Select/Search--' } as Contact, ...this.investorContacts] : [...this.investorContacts];
      this.selectedContact = selectedContactId;
      this.selectedContactValue = null;
      this.selectedContactValueSpaces = null
    }
    else {
      this.selectedContactValue = this.investorContacts.find(x => x.id == selectedContactId)?.upn.replace(/\s+/g, ' ').trim();
      this.selectedContactValueSpaces = this.investorContacts.find(x => x.id == selectedContactId)?.upn;
    }

  }

  private revokeJWTTokenonRefresh() {
    var encryptedCid = sessionStorage.getItem('cid');
    var isOTP = sessionStorage.getItem('authmode');
    if (isOTP && isOTP == otpAuth) {
      this.loginSvc.revokeTokenOnRefresh(encryptedCid, this.appService.getOTPStateToken()).subscribe(response => {
        if (response > 0) {
          this.appService.setOTPStateToken('');
          this.appService.setOTPAccessToken('');
          sessionStorage.removeItem('otpaccesstoken');
          sessionStorage.removeItem(stateTokenSessionVarKey);
        }
      });
    }
  }

  // private flushOTPKeys(){
  //   this.appService.setOTPStateToken('');
  //   this.appService.setOTPAccessToken('');
  //   sessionStorage.removeItem('otpaccesstoken');
  //   sessionStorage.removeItem(stateTokenSessionVarKey);
  //   this.navigationSvc.navigate('login');
  // }


  private listenToLogout() {
    const broadcastChannel = new BroadcastChannel('logout_channel');
    broadcastChannel.onmessage = (event) => {
      if (sessionStorage['loggedUserName']) {
        // logout all tabs for logout button click
        if (event.data.action == 'logout') {
          this.navigateToLogin();
        }
        else if (event.data.action == 'sessionidleLogout') { // option written to prevent timer being stuck at login of duplicate tabs for MSAL
          //navigate to MSAL logout screen
        }
        else if (event.data.action == 'sessionidleLogoutOTP') {
          sessionStorage.removeItem(SESSION_STORAGE.LOGIN_INITIATED);
          this.navigateToLogin();
          this.sessionidleModal?.elementRef?.nativeElement?.classList.remove('show');
          this.sessionidleModal?.elementRef?.nativeElement?.classList.add('fade');
          this.loginSvc.revokeToken(this.appService.getOTPStateToken()).subscribe(response => {
            if (response > 0) {
              this.appService.setOTPStateToken('');
              this.appService.setOTPAccessToken('');
              sessionStorage.removeItem(stateTokenSessionVarKey);
              sessionStorage.removeItem('otpaccesstoken');
            }
          })
        }
      }
    }
  }

  navigateToLogin() {
    if (sessionStorage.getItem(MultiLoginUrlSessionKey) === otpAuth || sessionStorage.getItem(MultiLoginUrlSessionKey) === ADL) {
      this.router.navigate([NAVIGATION.LOGIN_URL, btoa(sessionStorage.getItem(MultiLoginUrlSessionKey))]);
    } else {
      this.router.navigate([NAVIGATION.LOGIN_URL]);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(_event: any) {
    if (window.location.href.indexOf('app-root') > -1)
      this.appInsight.logEvent('Browser Close action on Investor portal');
    location.reload();
  }
}
