import { Injectable, NgZone } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { AppService } from './app.service';
import { LoginAuthService } from 'src/app/login/login-auth.service';
import { LoginService } from 'src/app/login/login.service';
import { ADL, MultiLoginUrlSessionKey, otpAuth, stateTokenSessionVarKey } from '../constants/common.constants';
import { NAVIGATION } from '../components/session-idle/session-idle-constants';
import { SESSION_STORAGE } from 'src/app/configs/common-config';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _isFreshLoad = true;
  private _previousRoute: string = '';
  private $navigationSubscription: Subscription;

  constructor(
    private router: Router, private authService: MsalService, private loginService: LoginAuthService,
    private zone: NgZone, private route: ActivatedRoute, private appSvc: AppService, private otpLoginSvc: LoginService) {
    this.$navigationSubscription = this.router.events
      .pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(() => window.scroll({ top: 0 }));
  }

  public get isFreshLoad(): boolean {
    return this._isFreshLoad;
  }

  firstTimeLoadComplete() {
    this._isFreshLoad = false;
  }

  navigate(path: string, skipLocationChange: boolean = false) {
    this._previousRoute = this.router.routerState.snapshot.url;
    this.router.navigate([path], { skipLocationChange });
  }

  navigate_login(path: string, loginType: string, skipLocationChange: boolean = false) {
    this._previousRoute = this.router.routerState.snapshot.url;
    this.router.navigate([path, loginType], {skipLocationChange});
  }

  navigateRelative(path: string, skipLocationChange: boolean = false) {
    this._previousRoute = this.router.routerState.snapshot.url;
    this.router.navigate([path], { relativeTo: this.route.parent, skipLocationChange });
  }

  initialNavigate() {
    this.router.initialNavigation();
  }

  logOut() {
    var isOTP = sessionStorage.getItem('authmode');
    if(isOTP && isOTP == otpAuth){
        this.otpLoginSvc.revokeToken(this.appSvc.getOTPStateToken()).subscribe(response =>{
        if(response > 0){          
          this.appSvc.setOTPStateToken('');
          this.appSvc.setOTPAccessToken('');
          sessionStorage.removeItem('otpaccesstoken');
          sessionStorage.removeItem(stateTokenSessionVarKey);
          sessionStorage.removeItem(SESSION_STORAGE.LOGIN_INITIATED);          
          sessionStorage.removeItem('authmode');         
          sessionStorage.removeItem('dcid');
          const broadcastChannel = new BroadcastChannel('logout_channel');
          broadcastChannel.postMessage({action:'logout'});
          broadcastChannel.close();          
        }        
      })
    }
    else{
      this.appSvc.flushState();
      this.authService.logout();
      this.loginService.logOut();     
    }    
  }

  onBack() {
    // this._previousRoute = (this._previousRoute.replace('/(scwOutlet:', '')).replace(')', '');
    // this.router.navigate([{ outlets: { 'scwOutlet': this._previousRoute } }]);
  }

  dispose() {
    if (this.$navigationSubscription && !this.$navigationSubscription.closed) {
      this.$navigationSubscription.unsubscribe();
    }
  }

  navigateToCompositeHome() {
    this.zone.run(() => this.router.navigate(['services/composite-service']));
  }

  navigateToRedirectPage() {
    this.zone.run(() => this.router.navigate(['redirect']));
  }

  navigateToServiceOfferingPage(isV2UIEnabled:boolean = false) {
    if(isV2UIEnabled){
      this.zone.run(() => this.router.navigate(['services-v2']));
    }
    else{
      this.zone.run(() => this.router.navigate(['services']));
    }
  }

  navigateToWithholdingDocsPage() {
    this.zone.run(() => this.router.navigate(['services/withholding-service/documents']));
  }

  navigateToWithholdingHome() {
    this.zone.run(() => this.router.navigate(['services/withholding-service']));
  }

  navigateToWHEntityGrid(){
    this.zone.run(() => this.router.navigate(['services/withholding-service/survey']));    
  }

  navigateToWHDashboard(){
    this.zone.run(() => this.router.navigate(['services/withholding-service/survey/dashboard']));  
  }

}
