import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, timer, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { preloadTimer } from '../../../assets/constants/app-constants';
import { Injectable } from "@angular/core";

@Injectable()
export class AppPreloadingStrategy implements PreloadingStrategy {
  preload(route: Route, load: Function): Observable<any> {

    const loadRoute = (delay) => delay ? timer(preloadTimer).pipe(mergeMap(_ => load())) : load();

    return route.data && route.data.preload  ? loadRoute(route.data.delay) : of(null);
  }
}
