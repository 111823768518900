import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlProviderService {

  private readonly baseUrl: string = 'SCWP/api/';
  private readonly taxDocumentsBaseUrl: string = 'DocumentService/api/'
  private readonly withHoldingBaseUrl: string = 'wh/api/';
  private readonly documentdropoffBaseUrl: string = 'tddo/api/';
  private readonly pteBaseUrl: string = 'pte/api/';
  //-------------- OTP Login button functionality---------------------------
  private readonly otpController: string = this.baseUrl + 'OTPAuth';
  //-------------- OTP Login button functionality---------------------------
  private readonly userController: string = this.baseUrl + 'User';
  private readonly partnerController: string = this.baseUrl + 'Partner';
  private readonly taxYearController: string = this.baseUrl + 'TaxYear';
  private readonly serviceOfferingController: string = `${this.baseUrl}ServiceOffering`;
  private readonly compositeController: string = this.baseUrl + 'Composite';
  private readonly documentController: string = this.baseUrl + 'Document';
  private readonly taxDocumentsController: string = this.taxDocumentsBaseUrl + 'TaxDocuments';
  private readonly fundController: string = this.taxDocumentsBaseUrl + 'Fund';
  private readonly withholdingController: string = this.withHoldingBaseUrl + 'Withholding';
  private readonly whPartnerController: string = this.withHoldingBaseUrl + 'Partners';
  private readonly whEntityController: string = this.withHoldingBaseUrl + 'Entity';
  private readonly whDocumentController: string = this.withHoldingBaseUrl + 'Document';
  private readonly whSurveyPeriodController: string = this.withHoldingBaseUrl + 'SurveyPeriod';
  private readonly whExemptionController: string = this.withHoldingBaseUrl + 'Exemption';
  private readonly documentDropOffController: string = this.documentdropoffBaseUrl + 'DocumentDropoff';
  private readonly tddoDocAttributeController: string = this.documentdropoffBaseUrl + 'DocumentAttribute';
  private readonly tddoDocumentController: string = this.documentdropoffBaseUrl + 'Documents';
  //temporary esignature controller
  private readonly whSignatureController: string = this.withHoldingBaseUrl + 'ESignature';

  //Investor Profile Urls
  private readonly partnerIPController: string = this.baseUrl + 'InvestorProfile';

  //#region OTP Auth Controller
  public get generateOTP(): string {
    return environment.apiEndpoint + this.otpController + '/GenerateOTP';
  }
  public get validateOTP(): string {
    return environment.apiEndpoint + this.otpController + '/ValidateOTP';
  }
  public get refresh() : string{
    return environment.apiEndpoint + this.otpController + "/refresh";
  }
  public get revoke() : string{
    return environment.apiEndpoint + this.otpController + "/revoke";
  }
  public get validateSecondFactor() : string{
    return environment.apiEndpoint + this.otpController + "/ValidateSecondFactor";
  }
  public get revokerefresh() : string{
    return environment.apiEndpoint + this.otpController + "/revokerefresh";
  }
  //#end region OTP Auth Controller

  // #region User Controller
  public get isUserAuthorized(): string {
    return environment.apiEndpoint + this.userController + '/Authorize/';
  }

  public get getUserRole(): string {
    return environment.apiEndpoint + this.userController + '/PartnerSelect';
  }

  public get getMenuItems(): string {
    return environment.apiEndpoint + this.userController + '/Menu';
  }

  public get getConfiguration(): string {
    return environment.apiEndpoint + this.userController + '/GetConfiguration';
  }
  // #end region User Controller


  // #region Partner Controller

  public get getPartners(): string {
    return environment.apiEndpoint + this.partnerController + '/Partners';
  }
  public get getPartnersByInvestor(): string {
    return environment.apiEndpoint + this.partnerController + '/PartnersByInvestor';
  }
  public get checkPIConsentFY(): string {
    return environment.apiEndpoint + this.partnerController + '/Consent';
  }
  public get getTaxYearPartnerInfo(): string {
    return environment.apiEndpoint + this.partnerController;
  }
  public get saveTaxYearPartnerInfo(): string {
    return environment.apiEndpoint + this.partnerController;
  }
  public get getSurveysRemainingCount(): string {
    return environment.apiEndpoint + this.partnerController + '/SurveysRemainingCount';
  }
  public get getSurveysRemainingCountByInvestor(): string {
    return environment.apiEndpoint + this.partnerController + '/SurveysRemainingCountByInvestor';
  }
  public get getInvestorContacts(): string {
    return environment.apiEndpoint + this.partnerController + '/GetInvestorContacts';
  }

  public get getAllMaritalStatus(): string {
    return environment.apiEndpoint + this.partnerController + '/AllMaritalStatus';
  }

  // //#end region Partner Controller

  // #region Tax Year Controller
  public get getCurrentYearTaxData(): string {
    return environment.apiEndpoint + this.taxYearController + '/Current';
  }

  public get getLegalEntityTypeList(): string {
    return environment.apiEndpoint + this.taxYearController + '/EntityTypes';
  }
  // //#end region Tax Year Controller

  // #region Composite Controller
  public get getPreviousElectionData(): string {
    return environment.apiEndpoint + this.compositeController;
  }
  public get getAvailableJurisdictions(): string {
    return environment.apiEndpoint + this.compositeController + '/AvailableJurisdictions';
  }
  public get saveorSubmitElectionData(): string {
    return environment.apiEndpoint + this.compositeController;
  }
  public get getCompositeDashboardData(): string {
    return environment.apiEndpoint + this.compositeController + '/InvestorDashboard';
  }
  // //#end region Composite Controller

  public get getResidencyStatesAndEntityTypes(): string {
    return environment.apiEndpoint + this.compositeController + 'getResidencyStatesAndEntityTypes';
  }

  public get getAvailableTaxYears(): string {
    return environment.apiEndpoint + this.taxYearController + '/All';
  }

  public get getStates(): string {
    return environment.apiEndpoint + this.withholdingController + 'getWithholdingEntitiesData';
  }  

  public get getAvailableServices(): string {
    return `${environment.apiEndpoint}${this.serviceOfferingController}/Investor`;
  }
  
  public get getConsolidatedPartners(): string {
    return environment.apiEndpoint + this.serviceOfferingController + '/ConsolidatedPartnerData';
  }
  
  public get getConsolidatedPartnersByInvestor(): string {
    return environment.apiEndpoint + this.serviceOfferingController + '/ConsolidatedPartnerDataByInvestor';
  }

  public get getConsolidatedPartnerById(): string {
    return environment.apiEndpoint + this.serviceOfferingController + '/ConsolidatedPartnerById';
  }
  
  public get getCompositeSurveysRemainingCount(): string {
    return environment.apiEndpoint + this.serviceOfferingController + '/SurveysRemainingCount';
  }

  public get getCompositeSurveysRemainingCountByInvestor(): string {
    return environment.apiEndpoint + this.serviceOfferingController + '/SurveysRemainingCountByInvestor';
  }

  public get saveCompositePartner(): string {
    return environment.apiEndpoint + this.serviceOfferingController + '/SaveCompositePartner';
  }

  public get saveIPPartner(): string {
    return environment.apiEndpoint + this.serviceOfferingController + '/SaveIPPartner';
  }

  public get updateSurveyStage(): string {
    return environment.apiEndpoint + this.serviceOfferingController + '/UpdateSurveyStage';
  }

  public get getPYElectionData(): string {
    return environment.apiEndpoint + this.compositeController + '/GetPYElectionData';
  }
  
  // #region Withholding API Endpoints
  public get getWHCurrentYearTaxData(): string {
    return environment.withholdingApiEndpoint + this.whSurveyPeriodController + '/current';
  }
  public get getWHAvailableJurisdictions(): string {
    return environment.withholdingApiEndpoint + this.whExemptionController + '/availablejurisdictions';
  }
  public get getWithholdingData(): string {
    return environment.apiEndpoint + this.withholdingController + '/getWithholdingData';
  }
  public get getWithholdingExemptionData(): string {
    return environment.withholdingApiEndpoint + this.whExemptionController;
  }
  public get getWithholdingEntitiesData(): string {
    return environment.apiEndpoint + this.withholdingController + '/getWithholdingEntitiesData';
  }
  public get getUpdatedWithholdingData(): string {
    return environment.apiEndpoint + this.withholdingController + '/getUpdatedWithholdingData';
  }
  public get getWithholdingTaxYears(): string {
    return environment.apiEndpoint + this.withholdingController + '/getWithholdingTaxYears';
  }
  public get saveSubmitWithholdingData(): string {
    return environment.withholdingApiEndpoint + this.whExemptionController;
  }
  public get getEntityTypes(): string {
    return environment.apiEndpoint + this.withholdingController + '/getEntityTypes';
  }
  public get getWithholdingPartners(): string {
    return environment.withholdingApiEndpoint + this.whPartnerController;
  }
  public get consentPartnerInfo(): string {
    return environment.withholdingApiEndpoint + this.whPartnerController + '/consent'
  }
  public get getWithholdingPartnerSurveyCount(): string {
    return environment.apiEndpoint + this.partnerController + '/surveyCount';
  }
  public get getWHPendingSurveyCount(): string {
    return environment.withholdingApiEndpoint + this.whExemptionController + '/pending';
  }
  public get getWHPendingDocumentCount(): string {
    return environment.withholdingApiEndpoint + this.whExemptionController + '/pendingdocument';
  }
  public get getWithholdingTaxYearWithEntities(): string {
    return environment.withholdingApiEndpoint + this.whExemptionController + '/entities';
  }
  public get getWithholdingPendingEntities(): string {
    return environment.withholdingApiEndpoint + this.whExemptionController + '/entities/pending';
  }
  public get getWithholdingPendingDocuments(): string {
    return environment.withholdingApiEndpoint + this.whExemptionController + '/pendingdocumentsfordashboard';
  }
  public getWithholdingDashboardData(entityId: string): string {
    return environment.withholdingApiEndpoint + this.whExemptionController + '/entities/' + entityId;
  }
  public getJurisdictionEnityDocument(partnerId: string): string {
    return environment.withholdingApiEndpoint + this.whPartnerController + '/' + partnerId + '/documents';
  }
  public get getWithholdingAllDocuments(): string {
    return environment.withholdingApiEndpoint + this.whDocumentController + '/partnerfilledforms';
  }
  public get UploadFilledWithholdingDocuments(): string {
    return environment.withholdingApiEndpoint + this.whDocumentController + '/uploadfilledforms';
  }
  public get updateWithholdingDocumentStatus(): string {
    return environment.withholdingApiEndpoint + this.whDocumentController + '/markascomplete';
  }

  public get getWithholdingDocumentFile(): string {
    return environment.withholdingApiEndpoint + this.partnerController + '/documents/fileName';
  }
  public get saveWithholdingDocumentFile(): string {
    return environment.withholdingApiEndpoint + this.partnerController + '/documents';
  }
  public get getWHLegalEntityTypes(): string {
    return environment.withholdingApiEndpoint + this.whSurveyPeriodController + '/entitytypes';
  }

  public get UploadWithholdingADocuments(): string {
    return environment.withholdingApiEndpoint + this.whDocumentController;
  }

  public get downloadWithholdingDocument(): string {
    return environment.withholdingApiEndpoint + this.whDocumentController + '/download';
  }

  public get bulkDownloadUrl(): string {
    return environment.withholdingApiEndpoint + this.whDocumentController + '/bulkdownload';
  }

  public get fileDownloadStatus(): string {
    return environment.withholdingApiEndpoint + this.whDocumentController + '/downloadFilesStatus';
  }

  // #end region Withholding API Endpoints

  // #region Documents

  public get getDocuments(): string {
    return environment.apiEndpoint + this.documentController;
  }

  public get uploadDocument(): string {
    return environment.apiEndpoint + this.documentController;
  }

  public get downloadDocument(): string {
    return environment.apiEndpoint + this.documentController + '/download';
  }

  // #end region Documents

  // #region TaxDocuments

  public get getTaxDocuments(): string {
    return environment.documentApiEndpoint + this.taxDocumentsController;
  }

  public get downloadTaxDocuments(): string {
    return environment.documentApiEndpoint + this.taxDocumentsController + '/download';
  }

  // #end region TaxDocuments

  // #region DocumentsService API Endpoints
  public get fundDetail(): string {
    return environment.documentApiEndpoint + this.fundController;
  }

  public get consentFund(): string {
    return environment.documentApiEndpoint + this.fundController + '/ConsentFund';
  }

  // #end region DocumentsService API Endpoints

  //#region ESignature API Endpoints
  public get getDocuSignESignatureUrl(): string {
    return environment.withholdingApiEndpoint + this.whSignatureController;
  }

  public get getDocuSignSaveStatusUrl(): string {
    return environment.withholdingApiEndpoint + this.whSignatureController;
  }
  //#endregion

  //#region document-dropoff API Endpoints
  //getAttributesAndPartners
  public get getAttributesAndPartners(): string {
    return environment.documentdropoffApiEndpoint + this.tddoDocAttributeController + "/GetAttributesAndPartners";
  }
  //get all documentList
  public get getDocumentDropOff(): string {
    return environment.documentdropoffApiEndpoint + this.documentDropOffController + "/GetDocuments";
  }

  //get all tddo documen
  public get getTddoDocument(): string {
    return environment.documentdropoffApiEndpoint + this.tddoDocumentController + "/All";
  }
  //Upload document
  public get uploadDocumentDropOff(): string {
    return environment.documentdropoffApiEndpoint + this.documentDropOffController + "/UploadDocument";
  }
  //get all document names
  public get getDocumentNameList(): string {
    return environment.documentdropoffApiEndpoint + this.documentDropOffController + "/GetDocumentName";
  }
  //get all partner name
  public get getPartnerList(): string {
    return environment.documentdropoffApiEndpoint + this.documentDropOffController + "/GetPartnerName";
  }
  //get all Tax Year
  public get getTaxYearList(): string {
    return environment.documentdropoffApiEndpoint + this.documentDropOffController + "/GetTaxYear";
  }
  //update document 
  public get updateDocumentDropoff(): string {
    return environment.documentdropoffApiEndpoint + this.documentDropOffController + "/UpdateDocument";
  }
  //delete documents
  public get deleteDropOffDocuments(): string {
    return environment.documentdropoffApiEndpoint + this.documentDropOffController + "/DeleteDocument";
  }

  //download documents
  public get downloadDropOffDocuments(): string {
    return environment.documentdropoffApiEndpoint + this.tddoDocumentController + "/Download";
  }
  //#endregion


  // #region Investor profile 

  public get getIPPartners(): string {
    return environment.apiEndpoint + this.partnerIPController + '/Partners';
  }

  public get getIPPartnersByInvestor(): string {
    return environment.apiEndpoint + this.partnerIPController + '/PartnersByInvestor';
  }

  public get consentPartnerInfoIP(): string {
    return environment.apiEndpoint + this.partnerIPController + '/consent'
  }
  //#End Region


  public get getPtePartnerConsents(): string {
    return environment.pteApiEndPoint + this.pteBaseUrl + "Consent";
  }

  public get savePtePartnerConsents(): string {
    return environment.pteApiEndPoint + this.pteBaseUrl + "Consent/PartnerConsent";
  }
  public get checkTaxDocumentConsent(): string {
    return environment.documentApiEndpoint + this.taxDocumentsController + "/CheckConsent";
  }

  
  public get getAllowedStatesForWH(): string {
    return environment.withholdingApiEndpoint + this.whExemptionController + '/getAllowedStatesForWH';
  }

  public get getPteTaxMemo(): string {
    return environment.pteApiEndPoint + this.pteBaseUrl + "Document/Download";
  }

  public get getWhPartnerInfo(): string {
    return environment.withholdingApiEndpoint + this.whPartnerController + "/PartnerInfo";
  }
}
