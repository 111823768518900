import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Contact } from 'src/app/shared/models/iContact';
import { IServiceOffering } from 'src/app/shared/models/iServiceOffering.model';
import { UrlProviderService } from 'src/app/shared/services/url-provider.service';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  private $changeContact: Subject<boolean> = new Subject();

  constructor(private urlSvc: UrlProviderService, private httpClient: HttpClient) { }

  getInvestorContacts(): Observable<Contact[]> {
    return this.httpClient.get<Contact[]>(this.urlSvc.getInvestorContacts);
  }

  ChangeContact() {
    this.$changeContact.next(true);
  }

  getAvailableServices(contactGuid?: string) : Observable<IServiceOffering[]>{
    let opt = {}
    if(contactGuid)
      opt = { params: new HttpParams({fromString: `contactGuid=${contactGuid}`})};
    return this.httpClient.get<IServiceOffering[]>(this.urlSvc.getAvailableServices, opt);
  }

  get ChangeContactEvent(): Observable<boolean> {
    return this.$changeContact.asObservable();
  }
}
