import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AppService, IServiceConfiguration, NavigationService, UserRole } from '@app/shared';
import { Subject, takeUntil, take, Subscription } from 'rxjs';
import { COMPOSITE_CONFIGS } from '../service-offering/composite-service/constants/composite-common.constants';
import { LandingService } from '../service-offering/landing/landing.service';
import { IServiceOffering } from '../shared/models/iServiceOffering.model';
import { LoginService } from '../login/login.service';
import { LoaderService, NotificationService } from 'eycomponentlibrary';
import { RaptorUiLoggerService } from 'raptor-appinsightlogger-ui';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrl: './redirect.component.scss'
})
export class RedirectComponent implements OnInit, OnDestroy {

  $componentIsDestroyed: Subject<boolean> = new Subject<boolean>();
  isV2UIEnabled: boolean;
  availableServices: IServiceOffering[] = [];
  showLoaderIndicator = false;
  contactId: string;
  userRole: UserRole;
  $isComponentDestroyed: Subject<boolean> = new Subject();
  $checkContactId: Subscription;

  constructor(private appService: AppService, private navigationSvc: NavigationService,
    private landingSvc: LandingService, private loginSvc: LoginService,
    private notificationSvc: NotificationService, private ngZone: NgZone,
    private appInsight: RaptorUiLoggerService, private authService: MsalService,
    public loaderService: LoaderService) {

  }

  ngOnInit(): void {
    this.fetchUserRole();

    //Contact id will be set for Client Admin (CA) once the contact is selected
    this.$checkContactId = this.appService.InvestorContactId.pipe(takeUntil(this.$isComponentDestroyed)).subscribe(contactId => {
      this.contactId = contactId;
      if (this.userRole == UserRole.CA && contactId != null)
        this.redirectToServiceOffering();
    });

    if (this.userRole != undefined && this.userRole != null && this.userRole > 0) {
      if (this.userRole != UserRole.CA) {
        // For user roles other than Client Admin (CA)
        this.redirectToServiceOffering();
      }
    }
    // else {
    //   this.appInsight.logEvent('UserRole for' + this.authService.instance.getActiveAccount()?.username + ' incorrect');
    //   this.notificationSvc.error('You are not authorized to access this application!');
    //   this.ngZone.run(() => this.showLoaderIndicator = false);
    // }


  }
  fetchUserRole() {
    this.appService.GetUserRole.pipe(takeUntil(this.$componentIsDestroyed)).subscribe(userRole => {
      this.userRole = userRole;
    }),
      error => {
        this.appInsight.logException(error);
      };

  }

  redirectToServiceOffering() {
    this.isV2UIEnabled = false;
    this.landingSvc.getAvailableServices(this.contactId).pipe(take(1)).subscribe(services => {
      if (services) {
        this.availableServices = services;
        this.appService.setCEEnabled(this.availableServices.findIndex(x => x.serviceName == 'Composite Election') != -1);
        this.appService.setIPEnabled(this.availableServices.findIndex(x => x.serviceName == 'Investor Profile') != -1);

        this.appService.setServices(services);
        if ((this.availableServices.length == 1 && (this.appService.isCEEnabled)
          //Either one of Composite or IP is the only selection.
          //(this.availableServices.length == 1 && (this.appService.isCEEnabled || this.appService.isIPEnabled ))||
          //Both Composite and IP are selected.
          //(this.availableServices.length == 2
          //&& this.appService.isCEEnabled && this.appService.isIPEnabled 
        )) {

          this.appService.getConfiguration.pipe(take(1))
            .subscribe((content: IServiceConfiguration[]) => {
              if (content === null) {
                this.loginSvc.getConfiguration(COMPOSITE_CONFIGS)
                  .pipe(take(1))
                  .subscribe((configuration: IServiceConfiguration[]) => {
                    this.isV2UIEnabled = configuration?.find(x => x.value.name == 'NewUIEnabled')?.value?.value;
                    if (this.isV2UIEnabled)
                      this.navigationSvc.navigateToServiceOfferingPage(this.isV2UIEnabled);
                    else
                      this.navigationSvc.navigateToServiceOfferingPage(false);
                  },
                    (error) => {
                      console.error(error);
                      this.notificationSvc.error("Failed to load configurations. Please refresh the page.");
                    }
                  );
              }
              else if (content && content.length > 0) {
                if (content.filter(x => x.serviceName === "Composite Election").length === 0) {
                  this.loginSvc.getConfiguration(COMPOSITE_CONFIGS)
                    .pipe(take(1))
                    .subscribe((configuration: IServiceConfiguration[]) => {
                      content.push(...configuration);
                      this.isV2UIEnabled = configuration?.find(x => x.value.name == 'newUIEnabled')?.value?.value;
                      if (this.isV2UIEnabled)
                        this.navigationSvc.navigateToServiceOfferingPage(this.isV2UIEnabled);
                      else
                        this.navigationSvc.navigateToServiceOfferingPage(false);
                    },
                      (error) => {
                        console.error(error);
                        this.notificationSvc.error("Failed to load configurations. Please refresh the page.");
                      }
                    );
                }
              }
            });
        }
        else {
          this.navigationSvc.navigateToServiceOfferingPage(false);
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.$isComponentDestroyed) {
      this.$isComponentDestroyed.next(true);
      this.$isComponentDestroyed.unsubscribe();
    }
  }

}
