import { environment } from "src/environments/environment";
import { SESSION_STORAGE } from "./common-config";

export const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;

export const envConfig = environment;

export function msalExtraQueryParams() {
    return envConfig[getTenantType()].extraQueryParameter;
}

export function getInternalClient(): string {
    return envConfig.configInternal.clientId;
}

export function getExternalClient(): string {
    return envConfig.configExternal.clientId;
}

export function setInternalTenantType(): void {
    sessionStorage.setItem(SESSION_STORAGE.TENANT,'internal');
    sessionStorage.setItem(SESSION_STORAGE.LOGIN_INITIATED,'true');
}

export function setExternalTenantType(): void {
    sessionStorage.setItem(SESSION_STORAGE.TENANT,'external');
    sessionStorage.setItem(SESSION_STORAGE.LOGIN_INITIATED,'true');
}

export function getTenantType(): string {
    let tenant = (sessionStorage.getItem(SESSION_STORAGE.TENANT) == 'internal')
        ? 'configInternal': 'configExternal'
        //: (sessionStorage.getItem(SESSION_STORAGE.TENANT) == 'external') ? 'configExternal' :'configOTP';
    return tenant;
}

export function getProtectedResourceMap(): Map<string, Array<string>> {
    const tenantType = getTenantType();
    const resourceMaps = envConfig.protectedResourceUri;
    let protectedResourceMap = new Map<string, Array<string>>();
    if (resourceMaps !== undefined && resourceMaps !== null && resourceMaps.length > 0) {
        for (let index = 0; index < resourceMaps.length; index++) {
            protectedResourceMap.set(resourceMaps[index], envConfig[tenantType]?.scope);
        }
    }
    return protectedResourceMap;
}
