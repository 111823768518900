import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from './navigation.service';
import { MsalService } from '@azure/msal-angular';
import { otpAuth, stateTokenSessionVarKey } from '../constants/common.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  constructor(
    private navSvc: NavigationService,
    private authService: MsalService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var isOTP = sessionStorage.getItem('authmode');
    if (isOTP && isOTP == otpAuth && sessionStorage.getItem('otpaccesstoken') && sessionStorage.getItem(stateTokenSessionVarKey)) {
      return true;
    }
    else if (this.authService.instance.getActiveAccount() && (isOTP && isOTP != otpAuth)) {
      return true;
    }
    this.navSvc.navigate('login');  
    return false;
  }
}
