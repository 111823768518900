import { Injectable, TemplateRef } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class ModalService{
    private showModal$: Subject<{show:boolean, modalProps?:IModal}>;
    private modalProperties:IModal;
    response$: Subject<boolean>=new Subject();
    constructor(){
        this.showModal$ = new Subject();
    }

    /**Returns an observable to listen to modalChanges */
    public get Listener(){
        return this.showModal$.asObservable();
    }

    /**Show's the modal in screen. 
     * @returns True if primary button is clicked.
     * False if secondary button is clicked.
     */
    public show(modalProps?:IModal): Observable<boolean>{
        this.modalProperties=modalProps;
        this.showModal$.next({show: true, modalProps: modalProps});
        return this.response$.asObservable();
    }
    
    public hide(){
        this.showModal$.next({show: false, modalProps: this.modalProperties});
    }

    public set Response(value: boolean){
        this.response$.next(value);
    }
}

export interface IModal{
    hideCross?: boolean;
    /**Text to be shown in modal header. */
    headerText?:string;
    /**Text to be shown in modal body. */
    bodytext?:string;
    /**Template reference to be shown in modal body. */
    bodyTemplate?: TemplateRef<any>;
    /**Template reference to be shown in modal header. */
    headerTemplate?:TemplateRef<any>;
    /**Text that needs to be shown on the secondary button.*/
    cancelBtnText?: string;
    
    /**Text that needs to be shown on the primary button.*/
    confirmBtnText?: string;
}