import { Injectable } from '@angular/core';
import { UrlProviderService } from '../shared/services/url-provider.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IClient } from '../shared/models/iClient';
import { IConfiguration, IServiceConfiguration } from '../shared/models/iConfiguration';
import { map } from 'rxjs/operators';
import { iUserTokenResponse } from '../shared/models/iUserTokenResponse';
import { iUserTokenValidationResponse } from '../shared/models/iUserTokenValidationResponse';
import { iOTPValidationReponse } from '../shared/models/iOTPValidationReponse';
import { iOTPGenerationReponse } from '../shared/models/iOTPGenerationReponse';
import { iSecondFactorValidationResponse } from '../shared/models/iSecondFactorValidationResponse';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private urlSvc: UrlProviderService, private httpClient: HttpClient) { }

  isAuthorizedUser(encryptedClientId: string): Observable<IClient> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.httpClient.get<IClient>(this.urlSvc.isUserAuthorized + (!!encryptedClientId ? encryptedClientId : 'NA'));
  }

  getUserRole(): Observable<number> {
    const httpParam = new HttpParams()
    .set("sourceApplication", "InvestorPortal")
    return this.httpClient.get<number>(this.urlSvc.getUserRole, { params: httpParam });
  }
  getLevel2MenuItemsByClient(): Observable<string[]> {
    return this.httpClient.get<string[]>(this.urlSvc.getMenuItems);
  }

  getConfiguration(ids: string): Observable<IServiceConfiguration[]> {
    let configIds = {
      "id": ids
    };
    return this.httpClient.post<IServiceConfiguration[]>(this.urlSvc.getConfiguration, configIds).pipe(map((data: any[]) =>
      data?.map(x => <IServiceConfiguration>{
        serviceName: x?.serviceName,
        value: JSON.parse(x?.value)
      })
    ));
  }
  //-------------- OTP Login button functionality---------------------------
  generateOTP(emailId: string, encryptedClientId: string): Observable<iOTPGenerationReponse> {
    const httpParam = new HttpParams().append("encryptedClientId", encryptedClientId);
    const requestBody = { UserEmail: emailId };
    return this.httpClient.post<iOTPGenerationReponse>(this.urlSvc.generateOTP, requestBody, { params: httpParam });
  }

  validateOTP(emailId: string, passcode: string, stateToken: string, encryptedClientId: string): Observable<iOTPValidationReponse> {
    const requestBody = {
      UserTokenValidationRequest: { UserEmail: emailId, PassCode: passcode, StateToken: stateToken },
      OTPValidationParametersRequest: { EncryptedClientId: encryptedClientId }
    };
    //const httpParam = new HttpParams().set("encryptedClientId", encryptedClientId).append("isSSNopted",isSSNopted).append("ssnText",ssnText);
    return this.httpClient.post<iOTPValidationReponse>(this.urlSvc.validateOTP, requestBody);
  }

  refreshToken(jwtToken: string, stateToken: string): Observable<any> {
    return this.httpClient.post<any>(this.urlSvc.refresh, { jwtToken: jwtToken, stateToken: stateToken });
  }

  revokeToken(stateToken: string): Observable<any> {
    return this.httpClient.post<any>(this.urlSvc.revoke, { stateToken: stateToken });
  }

  revokeTokenOnRefresh(encryptedClientId: string, stateToken: string): Observable<any> {
    const httpParam = new HttpParams().append("encryptedClientId", encryptedClientId);
    return this.httpClient.post<any>(this.urlSvc.revokerefresh, { stateToken: stateToken }, { params: httpParam });
  }

  validateSecondFactor(emailId: string, passcode: string, stateToken: string, encryptedClientId: string, options: string, ssn: string, zipCode: string, partnerCode: string): Observable<iSecondFactorValidationResponse> {
    const requestBody = {
      UserTokenValidationRequest: { UserEmail: emailId, PassCode: passcode, StateToken: stateToken },
      OTPValidationParametersRequest: { EncryptedClientId: encryptedClientId, Options: options, SSN: ssn, ZipCode: zipCode, PartnerCode: partnerCode}
    };
    return this.httpClient.post<iSecondFactorValidationResponse>(this.urlSvc.validateSecondFactor, requestBody);
  }
  //-------------- OTP Login button functionality---------------------------
}
