import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RedirectComponent } from './redirect.component';
import { RouterModule } from '@angular/router';
import { ModalModule, SpinnerModule } from 'eycomponentlibrary';



@NgModule({
  declarations: [RedirectComponent],
  imports: [
    CommonModule,
    RouterModule,
    SpinnerModule,
    ModalModule
  ]
})
export class RedirectModule { }
