<div class="shell-modal" aria-labelledby="dialog-idleTimeout" [ngClass]="showPopup == true ? 'show' : 'fade'" #sessionidleModal>
    <div class="shell-modal-dialog shell-modal-dialog-centered">
        <div class="shell-modal-content">
            <div id="dialog-idleTimeout" class="shell-modal-header px-3 py-1">
                 <!-- material-icons class will be removed after CLD Component -->
                <b class="material-icons text-danger mt-2">&#9432;</b>
                <h5 id="dialog idle" class="shell-modal-title me-auto py-1 ms-2">No Activity Detected !!</h5>
            </div>
            <div class="shell-modal-body p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="card bg-light">
                            <div class="card-body">
                                <p>You have been idle for 29 minutes ...</p>
                                <p>You can continue your session by clicking
                                    <span class=" mx-1 badge badge-success pointer p-1"
                                        (click)="onContinue()">CONTINUE</span> Or you can
                                    Logout by clicking
                                    <span class=" mx-1 badge badge-danger pointer p-1" (click)="logOut()">LOGOUT</span>
                                </p>
                                <p> If no action is taken, then your session will be
                                    terminated automatically in <span
                                        class="badge badge-pill badge-warning">{{idleCounter}}</span>
                                    secconds for security reasons.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
