import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DropdownModule, ModalModule, NavModule, SpinnerModule, UtilityService,SearchableDropdownModule } from 'eycomponentlibrary';
import { LoginModule } from './login/login.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SCWHttpInterceptor } from './interceptor/scwhttp-interceptor';
import { NotificationModule } from 'eycomponentlibrary';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { SanitizeHtmlPipe } from './shared/pipes/sanitize-html.pipe';
import { FormsModule } from '@angular/forms';
import { FakeBackendInterceptor } from './fake-backend-interceptor';
import { AppPreloadingStrategy } from './shared/services/app.preload.service';
import { MsalProviders } from './shared/services/msal-auth.service';
import { SessionIdleModule } from './shared/components/session-idle/session-idle.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BrowserResolvereService } from './shared/services/resolve-guards/browser-resolvere.service';
import { EY_LOGGER_CONFIG,RaptorUiErrorHandler } from 'raptor-appinsightlogger-ui';
import { RedirectModule } from './redirect/redirect.module';

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        AppRoutingModule,
        FontAwesomeModule,
        NavModule,
        LoginModule,
        RedirectModule,
        SessionIdleModule,
        SpinnerModule,
        NotificationModule,
        DropdownModule,
        ModalModule,
        MsalModule,
        NgIdleKeepaliveModule.forRoot(),
        SearchableDropdownModule], providers: [
        { provide: EY_LOGGER_CONFIG, useFactory: EyConfigFactory },
        { provide: ErrorHandler, useClass: RaptorUiErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: SCWHttpInterceptor, multi: true },
        { provide: APP_BASE_HREF, useValue: window['base-href'] },
        SanitizeHtmlPipe,
        DatePipe,
        UtilityService,
        AppPreloadingStrategy,
        MsalProviders(),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

function EyConfigFactory() {
  return  environment.appInsights;
}
