import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { interceptorMessages } from 'src/assets/constants/app-constants';
import { mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { LoaderService, NotificationService } from 'eycomponentlibrary';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { UrlProviderService, AppService } from '@app/shared'
import { MsalAuthService } from '../shared/services/msal-auth.service';
import { AuthenticationResult, InteractionRequiredAuthError } from '@azure/msal-browser';
import { GetScopes, MSALGuardConfigFactory } from '../configs/msal-config';
import { RaptorUiLoggerService } from 'raptor-appinsightlogger-ui';
import { EventManager } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NAVIGATION } from '../shared/components/session-idle/session-idle-constants';
import { LoginService } from '../login/login.service';
import { msalAuth, otpAuth, stateTokenSessionVarKey } from '../shared/constants/common.constants';


@Injectable()
export class SCWHttpInterceptor implements HttpInterceptor {
  // Set the URLs and methods where showing loader is not required

  private loaderExcludedApis: Array<{ url: string, method: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT', key: string }> = [];
  private isOTPLogin: boolean;
  constructor(
    private appInsight: RaptorUiLoggerService,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
    private loaderService: LoaderService,
    private appSvc: AppService,
    private urlSvc: UrlProviderService,
    private dpInjector: Injector,
    private notifyService: NotificationService,
    private router: Router,
    private loginSvc: LoginService
    ) {

    const whDocsDownloadUnInterruptedUrl = this.urlSvc.fileDownloadStatus;
    this.loaderExcludedApis.push({ url: whDocsDownloadUnInterruptedUrl, method: "GET", key: "DocumentDownloadStatus" });

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.appInsight.logEvent('Request interception started for--' + request.urlWithParams + 'for user--' + this.authService.instance.getActiveAccount()?.username + 'for clientid--' + this.appSvc.Client?.id);
    //------------------ Checking for OTP to bypass MSAL interceptor logic---------------------------
    var isOTP = sessionStorage.getItem('authmode');
    if (isOTP && isOTP == msalAuth) {

      const msalService = this.dpInjector.get<MsalAuthService>(MsalAuthService);
      var scopes = null;
      if (msalService !== undefined) {
        var scopeRequest = GetScopes();
      }      
      if (!this.authService.instance.getActiveAccount()) {
        this.appInsight.logEvent(this.authService.instance.getActiveAccount()?.username + '--' + interceptorMessages.notAuthenticated);
        throw new Error(interceptorMessages.notAuthenticated);
      }
      if (!scopeRequest) {
        return next.handle(request);
      }
      let token: string;
      const requestUrlWithoutParam = request.url.indexOf('?') >= 0 ? request.url.substring(0, request.url.indexOf('?')) : request.url;
      const matchingAPI = this.loaderExcludedApis.find(x => x.url === requestUrlWithoutParam &&
        x.method.toUpperCase() === request.method.toUpperCase());
      if (!matchingAPI) {
        this.loaderService.pushApiInvocation('primary');
      } else {
        this.loaderService.pushApiInvocation(matchingAPI.key);
      }
      
      // Acquire a token for this request, and attach as proper auth header. 
      return from(
        msalService.getAccessTokenforINVP(scopeRequest)
          .toPromise()
          .then((token: any) => {
            //token = response.tokenType === 'id_token' ? response.idToken : response.accessToken;
            const authHeader = `Bearer ${token}`;
            return request.clone({
              setHeaders: {
                Authorization: authHeader,
              }
            });
          })
      ).pipe(
        mergeMap(nextReq => {
          let authorizedRequest;
          if (nextReq.url.includes(this.urlSvc.isUserAuthorized)) {
            authorizedRequest = nextReq.clone({
              headers: request.headers.set('Authorization', `Bearer ${token}`)
            }); 
            //Not required for calling the INVP Authorization API          
            // if ((nextReq.method === 'POST'|| nextReq.method == "PUT" || nextReq.method == "PATCH") && JSON.stringify(nextReq?.body) != '{}'){     
            //   authorizedRequest = authorizedRequest.clone({
            //     headers: authorizedRequest.headers.set('Content-Type', 'application/json')
            //   });
            // }
            return next.handle(authorizedRequest);
          } else {
            if (this.appSvc.Client) {
              authorizedRequest = nextReq.clone({
                headers: request.headers.set('Authorization', `Bearer ${token}`).set('X-ClientID', this.appSvc.Client.id)
              });
              if ((nextReq.method === 'POST'|| nextReq.method == "PUT" || nextReq.method == "PATCH") && JSON.stringify(nextReq?.body) != '{}'){     
                authorizedRequest = authorizedRequest.clone({
                  headers: authorizedRequest.headers.set('Content-Type', 'application/json')
                });
              }
              return next.handle(authorizedRequest);
            }
            return of(null);
          }
        }),
        tap(
          event => {
            if (event instanceof HttpResponse && (event.status === 200 || event.status === 201 || event.status === 202 || event.status === 204)) {
              if (!matchingAPI) {
                this.loaderService.popApiInvocation('primary');
              } else {
                this.loaderService.popApiInvocation(matchingAPI.key);
              }
            }
            if (event === null) {
              if (!matchingAPI) {
                this.loaderService.popApiInvocation('primary');
              } else {
                this.loaderService.popApiInvocation(matchingAPI.key);
              }
            }
          },
          err => {
            if (!matchingAPI) {
              this.loaderService.popApiInvocation('primary');
            } else {
              this.loaderService.popApiInvocation(matchingAPI.key);
            }
            if (err instanceof HttpErrorResponse && err.status === 401) {
              console.log('Unauthorized error 401')
            }
            if (err instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              return this.authService.loginRedirect();
            }
          }
        )
      );
    }
    else {
      const requestUrlWithoutParam = request.url.indexOf('?') >= 0 ? request.url.substring(0, request.url.indexOf('?')) : request.url;
      const matchingAPI = this.loaderExcludedApis.find(x => x.url === requestUrlWithoutParam &&
        x.method.toUpperCase() === request.method.toUpperCase());
      if (!matchingAPI) {
        this.loaderService.pushApiInvocation('primary');
      } else {
        this.loaderService.pushApiInvocation(matchingAPI.key);
      }

      if(request.url.includes(this.urlSvc.refresh)){
        if(this.appSvc?.Client?.id){
        request = request.clone({
          headers: request.headers.set('X-ClientID', this.appSvc.Client.id)
        });       
      }
      }
      return this.RefreshTokenIfNeeded(request).pipe(
        switchMap(() => {
          var OTPtoken = this.appSvc.getOTPAccessToken;
          if (OTPtoken && OTPtoken !='' && !request.url.includes(this.urlSvc.refresh)) {
              request = request.clone({
                headers: request.headers.set('X-ClientID', this.appSvc.Client?.id).set('Authorization', `Bearer ${OTPtoken}`).set('authmode', otpAuth)
              });
              if ((request.method === 'POST'|| request.method == "PUT" || request.method == "PATCH") && JSON.stringify(request?.body) != '{}'){     
                request = request.clone({
                  headers: request.headers.set('Content-Type', 'application/json')
                });
              }
          }
          // After refreshing the token (if needed), continue with the original request
          return next.handle(request).pipe(
            tap(event => {
              if (event instanceof HttpResponse && (event.status === 200 || event.status === 201 || event.status === 202 || event.status === 204)) {
                if (!matchingAPI) {
                  this.loaderService.popApiInvocation('primary');
                } else {
                  this.loaderService.popApiInvocation(matchingAPI.key);
                }
              }
              if (event === null) {
                if (!matchingAPI) {
                  this.loaderService.popApiInvocation('primary');
                } else {
                  this.loaderService.popApiInvocation(matchingAPI.key);
                }
              }
            },
              err => {
                if (!matchingAPI) {
                  this.loaderService.popApiInvocation('primary');
                } else {
                  this.loaderService.popApiInvocation(matchingAPI.key);
                }
                if (err instanceof HttpErrorResponse && err.status === 401) {
                  console.log('Unauthorized error 401')
                }
                if (err instanceof InteractionRequiredAuthError) {
                  // fallback to interaction when silent call fails
                  return this.authService.loginRedirect();
                }
              })
          );
        })
      );
      
    }
    //------------------ Checking for OTP to bypass MSAL interceptor logic---------------------------
  }
  checkTokenExpiry(token: string): boolean {
    if (token == '') return true;
    const jwtJson = this.getDecodedToken(token);
    var date = new Date();
    let dateTime=(date.getTime() / 1000);
    let res=jwtJson.exp < dateTime;
    return res;
  }

  getDecodedToken(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(
          (c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        )
        .join('')
    );
    return JSON.parse(jsonPayload);
  }


  RefreshTokenIfNeeded(request: any): Observable<any>{
    if(!(request.url.includes(this.urlSvc.refresh)) && this.appSvc.getOTPAccessToken != '' && this.checkTokenExpiry(this.appSvc.getOTPAccessToken)){
     if(this.appSvc.getOTPStateToken()){
    return this.loginSvc.refreshToken(this.appSvc.getOTPAccessToken, this.appSvc.getOTPStateToken()).pipe(
      tap((response: any) => {
        // Update the access token with the new one received from the server
        if(response)
           this.appSvc.setOTPToken(response?.jwtToken);
        else{
            console.log("Refresh token expired");
            sessionStorage.clear();
            this.notifyService.error("The current session has expired. Please login again.");
            this.router.navigate([NAVIGATION.LOGIN_URL]);
            return of(null);
        }
      })
    );
     }
     else{
      this.appSvc.setOTPStateToken('');
      this.appSvc.setOTPAccessToken('');
      sessionStorage.removeItem(stateTokenSessionVarKey);
      sessionStorage.removeItem('otpaccesstoken');      
      this.notifyService.error("The current session has ended. Please login again.");
      this.router.navigate([NAVIGATION.LOGIN_URL]);
      return of({jwtToken: this.appSvc.getOTPAccessToken});
     }
  }
  else{
  return of({jwtToken: this.appSvc.getOTPAccessToken});
  }
  }
}
