import { ErrorHandler, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './shared/services/auth-guard.service';
import { AppPreloadingStrategy } from './shared/services/app.preload.service';
import { RaptorUiErrorHandler } from 'raptor-appinsightlogger-ui';
import { BrowserResolvereService } from './shared/services/resolve-guards/browser-resolvere.service';
import { AppComponent } from './app.component';
import { RedirectComponent } from './redirect/redirect.component';

const routes: Routes = [
  { path: "login", component: LoginComponent, resolve: { browserData: BrowserResolvereService } },
  { path: "login/:type", component: LoginComponent, resolve: { browserData: BrowserResolvereService } },
  { path: "app", component: AppComponent },
  { path: "redirect", component: RedirectComponent},
  { path: "services", loadChildren: () => import('./service-offering/serviceOffering.module').then(h => h.ServiceOfferingModule), canActivate: [AuthGuardService] },
  { path: "services-v2", loadChildren: () => import('./service-offering-v2/service-offering-v2.module').then(h => h.ServiceOfferingV2Module), canActivate: [AuthGuardService] }  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: AppPreloadingStrategy })],
  exports: [RouterModule],
  providers: [
    { provide: ErrorHandler, useClass: RaptorUiErrorHandler },
  ],
})
export class AppRoutingModule { }
