import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: any,chkContext : boolean = true): SafeHtml {
    try {
      if(!chkContext)
      return value ? this._sanitizer.bypassSecurityTrustHtml(value) : '';      
      else
      return this._sanitizer.sanitize(SecurityContext.HTML, this._sanitizer.bypassSecurityTrustHtml(value));// this._sanitizer.sanitize(SecurityContext.URL, value));
      
    }
    catch (e) {
      console.error(e);
      return 'Unsafe content found. Please contact administrator';
    }
  }
}
