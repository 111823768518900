export const TAX_DOCUMENTS: string = "Tax Documents";
export const COMPOSITE: string = "Composite Election";
export const WITHHOLDING: string = "Withholding Exemption";
export const Document_DropOff: string = "Tax Documents Dropoff";
export const PassThroughEntity: string = "Pass Through Entity Tax Election";
export const Investor_Profile : string = "Investor Profile";

export const Service_Offerings=[
    {
        serviceName: COMPOSITE,
        route: 'composite-service'
    },
    {
        serviceName: TAX_DOCUMENTS,
        route: 'document-delivery/documents'
    },
    {
        serviceName: WITHHOLDING,
        route: 'withholding-service'
    },
    {
        serviceName: Document_DropOff,
        route: 'document-dropOff'
    },
    {
        serviceName: PassThroughEntity,
        route: 'pass-through-entity'
    },
    {
        serviceName: Investor_Profile,
        route: 'investor-profile'
    }
]
