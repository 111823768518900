<div appSpinner [visible]="showLoaderIndicator" mode="Full" [spinnerText]="loaderService.GetSpinnerText | async">
  <router-outlet *ngIf="!isIFrame"></router-outlet>
  <div *ngIf="error">
    <span>Token renewal failed. Please login again.</span>
    <button (click)="onLoginRedirect()">Log Out</button>
  </div>
</div>

<!--Modal for partner selection by client admin-->
<app-modal [openAuto]="showPartnerContacts" crossHide=false draggable="true" class="invConModal">
  <h4 class="app-modal-header mb-0">Investor Contacts</h4>
  <div class="app-modal-body d-flex col-12 flex-column invContDiv">
    <p class="d-flex" *ngIf="isCancelAvailable">
      <fa-icon [icon]="faWarning" class="pr-3 p-1"></fa-icon>
      <span class="info">This action will ignore your current progress. Please make sure to save any unsaved changes, before changing the contact.</span>
    </p>
    <div class="contactDropDown mr-auto ml-auto">
      <app-dropdown #filterDropdown [enableSearch]="true" [options]="investorContacts" label="Select Investor Contacts" textField="upn" valueField="id"
        [(ngModel)]="selectedContact" [dataFilter]="selectedContactValue" [dataFilterSpaces]="selectedContactValueSpaces" (keyup)="contactInputFieldChanged($event)" (on_changed)="selectInputChange($event)">
      </app-dropdown>
    </div>

  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn_primary mr-3" (click)="selectInvestor()"
      [disabled]="disableButton()">Select</button>
    <button type="button" class="btn_secondary mr-3" *ngIf="isCancelAvailable"
      (click)="cancelSelection()">Cancel</button>
  </div>
</app-modal>


<!--Generic modal for modal service-->
<app-modal [crossHide]="modalProperties?.hideCross" [openAuto]="showModal" (onHide)="onGenericModalHide($event)">
  <h4 *ngIf="modalProperties?.headerText" class="modal-title app-modal-header">{{modalProperties.headerText}}</h4>
  <ng-container *ngIf="modalProperties?.headerTemplate">
    <ng-template *ngTemplateOutlet="modalProperties.headerTemplate"></ng-template>
  </ng-container>
  <div class="app-modal-body">
    <ng-container *ngIf="modalProperties?.bodytext">{{modalProperties.bodytext}}</ng-container>
    <ng-container *ngIf="modalProperties?.bodyTemplate">
      <ng-template *ngTemplateOutlet="modalProperties.bodyTemplate"></ng-template>
    </ng-container>
  </div>
  <div *ngIf="modalProperties?.cancelBtnText || modalProperties?.confirmBtnText" class="app-modal-footer">
    <button class="btn_primary mr-3" *ngIf="modalProperties.confirmBtnText" type="button" (click)="setGenericModalResponse(true)">{{modalProperties.confirmBtnText}}</button>
    <button class="btn_secondary" *ngIf="modalProperties.cancelBtnText" type="button" (click)="setGenericModalResponse(false)">{{modalProperties.cancelBtnText}}</button>
  </div>
</app-modal>
<app-session-idle></app-session-idle>