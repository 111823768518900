import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { MsalInterceptor, MsalService } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { MSALInterceptorConfigFactory } from "../configs/msal-config";
import { MsalAuthService } from "../shared/services/msal-auth.service";
import { otpAuth } from "../shared/constants/common.constants";

@Injectable()
export class MsalSkipInterceptor extends MsalInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    var isOTP = sessionStorage.getItem('authmode');
    if (isOTP && isOTP == otpAuth) {
      return next.handle(request);
    }
    return super.intercept(request, next);
  }
}